import { Flex, Icon, Input, Link, Text, useDisclosure } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CONNECTIONS_STATE_KEY, createChargebee } from 'apis/connections';
import { useOrgDetailsQuery } from 'apis/organizations-apis';
import { InfoIconFilled } from 'components/icons';
import { Button } from 'components/ui/button';
import {
  DialogBackdrop,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from 'components/ui/dialog';
import { Field } from 'components/ui/field';
import { SmartInput } from 'components/ui/samrt-input';
import { Tooltip } from 'components/ui/tooltip';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import useQueryParam from 'hooks/useQueryParam';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { extractChargebeeSiteId } from 'utils/utils';

import { BusinessAddressPrompt } from '../../update-address-prompt';
import { ItemCard } from '../item-card';
import { ChargebeeIcon } from './icons/chargebee';

interface FormData {
  url: string;
  api_key: string;
}

export default function ChargebeeTab({ isConnected = false }: { isConnected?: boolean }) {
  const { orgId, isTest } = useOrg();
  const { open, onOpen, onClose } = useDisclosure();
  const { handleSuccessNotification } = useHandleNotification();
  const queryClient = useQueryClient();
  const [chargebeeData, setChargebeeData] = useState<FormData>({
    url: '',
    api_key: '',
  });
  const [isBusinessAddressPromptOpen, setIsBusinessAddressPromptOpen] = useState(false);
  // const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const [, setSearchParams] = useQueryParam('liveSource');
  const navigate = useNavigate();

  const { data } = useOrgDetailsQuery(orgId);
  const isAddressMissing = data ? !data.company_country_code || !data.company_postal_code : true;

  const handleOpenChargebeeModal = () => {
    setIsBusinessAddressPromptOpen(false);
    onOpen();
  };

  const chargebeeMutation = useMutation({
    mutationFn: () => {
      const transformedData = {
        url: chargebeeData.url.trim(),
        api_key: chargebeeData.api_key.trim(),
      };

      return createChargebee(orgId, transformedData.url, transformedData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [CONNECTIONS_STATE_KEY] });
      onClose();
      handleSuccessNotification('Chargbee Connected Successfully.');
      navigate('/source');
    },
  });

  const handleChargebeeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === 'url') {
      const siteId = extractChargebeeSiteId(value);
      setChargebeeData(prev => ({ ...prev, url: siteId }));
    } else {
      setChargebeeData(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmitChargebee = async () => {
    chargebeeMutation.mutate();
  };

  const handlePatternMatched = () => {
    setChargebeeData({ api_key: '', url: '' });
    onClose();
    setSearchParams({ liveSource: 'chargebee' });
  };

  // const handleMouseLeave = () => {
  //   setIsTooltipOpen(false);
  // };

  return (
    <>
      <ItemCard
        isConnected={isConnected}
        title="Chargebee"
        description="SaaS for Revenue Growth Management"
        icon={<ChargebeeIcon />}
        onConnect={() => (isAddressMissing ? setIsBusinessAddressPromptOpen(true) : onOpen())}
        btnProps={{ colorPalette: 'lightGray' }}
        installationGuide="https://intercom.help/kintsugi/en/articles/9940119-integration-chargebee"
      />
      <BusinessAddressPrompt
        isOpen={isBusinessAddressPromptOpen}
        onClose={() => setIsBusinessAddressPromptOpen(false)}
        onSuccess={handleOpenChargebeeModal}
      />
      <DialogRoot
        scrollBehavior="inside"
        closeOnInteractOutside={false}
        size={'lg'}
        open={open}
        onOpenChange={({ open }) => {
          if (!open) {
            onClose();
          }
        }}
      >
        <DialogBackdrop />
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Connect Chargebee</DialogTitle>
          </DialogHeader>
          <DialogBody>
            <Field label="Chargebee Site ID">
              <Input name="url" value={chargebeeData.url} onChange={handleChargebeeChange} />
            </Field>
            <Field
              label={
                <Flex direction={'row'} align="center" mt={2} gap="1">
                  <Text>Chargebee API key</Text>

                  <Tooltip
                    interactive
                    content={
                      <Flex direction="column" w="100%" justifyContent={'center'}>
                        <Text color="fffff">
                          Please use the read-only API key. You can find the instructions to get the API key{' '}
                          <Link
                            color="white"
                            target="_blank"
                            href="https://kintsugi.readme.io/docs/connect-chargebee-to-kintsugi-account"
                            rel="noreferrer"
                            textDecoration="underline"
                          >
                            here
                          </Link>
                          .{' '}
                        </Text>
                      </Flex>
                    }
                  >
                    <Icon mt={1.5} size={'lg'}>
                      <InfoIconFilled />
                    </Icon>
                  </Tooltip>
                </Flex>
              }
            >
              <SmartInput
                name="api_key"
                value={chargebeeData.api_key}
                onChange={handleChargebeeChange}
                shouldMatchPattern={isTest}
                pattern={'^live_\\w*$'}
                onPatternMatched={handlePatternMatched}
              />
            </Field>
          </DialogBody>
          <DialogFooter>
            <Flex gap={2}>
              <Button variant={'outline'} color="secondary" onClick={onClose}>
                Cancel
              </Button>
              <Button
                loading={chargebeeMutation.isPending}
                variant="solid"
                color="primary"
                width={'90px'}
                onClick={handleSubmitChargebee}
                disabled={chargebeeData.url === '' || chargebeeData.api_key === ''}
              >
                Save
              </Button>
            </Flex>
          </DialogFooter>
        </DialogContent>
      </DialogRoot>
    </>
  );
}
