import { Flex, List, ListItem, Text } from '@chakra-ui/react';
import { CheckCircle } from 'components/icons';
import { Button } from 'components/ui/button';
import {
  DialogBackdrop,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from 'components/ui/dialog';
import { useNavigate } from 'react-router-dom';
import { OnBoardingStepStatus } from 'types/onboarding';

type OrgDetailPromptProps = {
  isOpen: boolean;
  onClose: () => void;
  data?: OnBoardingStepStatus;
};

export const OrgDetailPrompt = ({ isOpen, onClose, data }: OrgDetailPromptProps) => {
  const navigate = useNavigate();

  const { physical_nexus_status, organization_details_status, bank_details_status } = data || {
    physical_nexus_status: false,
    organization_details_status: false,
    bank_details_status: false,
  };

  const started = organization_details_status || physical_nexus_status || bank_details_status;

  const handleContinue = () => {
    if (!organization_details_status) {
      navigate('/configurations/organization-setup');
    } else if (!bank_details_status) {
      navigate('/configurations/bank-details');
    } else {
      navigate('/configurations');
    }
    onClose();
  };
  return (
    <DialogRoot
      size={'lg'}
      open={isOpen}
      onOpenChange={({ open }) => {
        if (!open) {
          onClose();
        }
      }}
    >
      <DialogBackdrop />
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {started ? 'Please provide additional details for registration' : 'Required information unfilled'}
          </DialogTitle>
        </DialogHeader>
        <DialogBody>
          {started ? (
            <List.Root gap={'5px'}>
              <ListItem style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                <CheckCircle fill={organization_details_status ? '#38A169' : '#CFD0D8'} />
                <Text>Organization Details</Text>
              </ListItem>
              <ListItem style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                <CheckCircle fill={physical_nexus_status ? '#38A169' : '#CFD0D8'} />
                <Text>Physical Nexus</Text>
              </ListItem>
              <ListItem style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                <CheckCircle fill={bank_details_status ? '#38A169' : '#CFD0D8'} />
                <Text>Bank Details</Text>
              </ListItem>
            </List.Root>
          ) : (
            <Text>
              To register, please fill in &apos;Organization Setup,&apos; &apos;Bank Details,&apos; and &apos;Billing
              Details&apos; under &apos;Settings.&apos;
            </Text>
          )}
        </DialogBody>
        <DialogFooter>
          <Flex gap={2}>
            <Button variant={'outline'} onClick={onClose}>
              Cancel
            </Button>
            <Button variant={'solid'} colorPalette={'blue'} width={'90px'} onClick={handleContinue}>
              Continue
            </Button>
          </Flex>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};
