import { Badge, Box, Flex, HStack, Icon, Image, Text } from '@chakra-ui/react';
import { useAuthInfo, useLogoutFunction, useRedirectFunctions } from '@propelauth/react';
import { ACLCheck } from 'app/acl/aclcheck';
import { DoubleArrowLeftIcon, DoubleArrowRightIcon, WarningIcon } from 'components/icons';
import { Avatar, AvatarGroup } from 'components/ui/avatar';
import { Button } from 'components/ui/button';
import { LinkButton, LinkButtonProps } from 'components/ui/link-button';
import { MenuContent, MenuItem, MenuRoot, MenuTrigger } from 'components/ui/menu';
import { Tooltip } from 'components/ui/tooltip';
import { APP_ROUTES, ModuleInfo } from 'constants/app-constants';
import { storeJWT } from 'cookie-handler';
import { useOrg } from 'hooks/useOrg';
import useTracking from 'hooks/useTracking';
import { useOrgTasksAndStats } from 'pages/Dashboard/hooks/use-org-tasks-and-stats';
import { PartnerOrgName } from 'pages/PartnerOrg/components/partner-org-name';
import { useEffect, useState } from 'react';
import { FiLogOut, FiUser } from 'react-icons/fi';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import logoSm from './components/logo.sm.svg';
import logo from './components/logo.svg';
import { OrganizationSelector } from './components/org-selector';
import { PartnerOrgSelector } from './components/partner-org-selector';

type NavItemProps = {
  href: string;
  title: string;
  icon: React.ReactNode;
  isExpanded: boolean;
  warningMessage?: string;
} & LinkButtonProps;

const NavItem = ({
  title,
  href,
  icon: providedIcon,
  isExpanded = true,
  children,
  warningMessage,
  ...rest
}: NavItemProps) => {
  return (
    <Tooltip
      content={title}
      showArrow
      disabled={isExpanded}
      positioning={{ placement: 'right-start', offset: { crossAxis: 4 } }}
    >
      <LinkButton size="sm" variant="nav" asChild w={!isExpanded ? 'unset' : undefined} gap="3" {...rest}>
        <Link to={href}>
          {providedIcon && <Icon boxSize="24px">{providedIcon}</Icon>}
          <HStack gap={1.5}>
            {isExpanded ? children : null}
            {warningMessage && (
              <Tooltip content={warningMessage} positioning={{ placement: 'bottom-end' }}>
                <WarningIcon color="#FFB000" width="5" height="5" />
              </Tooltip>
            )}
          </HStack>
        </Link>
      </LinkButton>
    </Tooltip>
  );
};

type SidebarProps = {
  hideToggleBtn?: boolean;
  isExpanded?: boolean;
  onSidebarExpand?: () => void;
  onClose?: () => void;
};
export const Sidebar = ({ hideToggleBtn = false, isExpanded = true, onSidebarExpand, onClose }: SidebarProps) => {
  const { externalId, isTest } = useOrg();
  const { user } = useAuthInfo();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { redirectToOrgPage } = useRedirectFunctions();
  const propelLogout = useLogoutFunction();
  const { resetAnalytics } = useTracking();

  const active = APP_ROUTES.find(({ href }: ModuleInfo) => href === pathname);
  const [activeButton, setActiveButton] = useState<string | undefined>(active?.name);

  useEffect(() => {
    const newActive = APP_ROUTES.find(({ href }: ModuleInfo) => href === pathname);
    setActiveButton(newActive?.name);
  }, [pathname]);

  const logoutFunction = () => {
    localStorage.clear();
    storeJWT('');
    propelLogout(true);
    resetAnalytics();
  };

  const handleRedirect = (item: ModuleInfo) => {
    navigate(item.href);
    setActiveButton(item.name);
    onClose && onClose();
  };

  const renderLogo = isExpanded ? (
    <Flex gap={1} justify={'center'} align={'center'}>
      <Image src={logo} height={'26px'} />
      {isTest && (
        <Badge bg={'#DD6B20'} color={'white'} textTransform={'uppercase'} px={1} h={'20px'} fontSize={'12px'}>
          Test
        </Badge>
      )}
    </Flex>
  ) : (
    <Image src={logoSm} height={'26px'} onClick={onSidebarExpand} cursor={'pointer'} />
  );

  const { tasks } = useOrgTasksAndStats();

  const {
    registrations_to_finish,
    filings_to_approve,
    pending_product,
    needs_update_connections_count,
    registrations_regime_to_change,
    registrations_regime_to_acknowledge,
  } = tasks;

  const registrationTaskCount = registrations_regime_to_change + registrations_regime_to_acknowledge;
  const tasksToShow = [
    {
      name: 'Nexus',
      tooltipContent: `${registrations_to_finish} Registration to Finish`,
      count: registrations_to_finish,
    },
    {
      name: 'Filings',
      tooltipContent: `${filings_to_approve} Filing to Approve`,
      count: filings_to_approve,
    },
    {
      name: 'Products',
      tooltipContent: `${pending_product} Product to Approve`,
      count: pending_product,
    },
    {
      name: 'Data Sources',
      tooltipContent: `${needs_update_connections_count} Connection to Update`,
      count: needs_update_connections_count,
    },
    {
      name: 'Registrations',
      tooltipContent: `${registrationTaskCount} Registration to Update`,
      count: registrationTaskCount,
    },
  ];

  const getTaskWarning = (taskName: string) => {
    const task = tasksToShow.find(t => t.name === taskName);
    return task?.count && task.count > 0 ? task.tooltipContent : undefined;
  };

  return (
    <Flex
      as={'nav'}
      data-onboarding="sidebar"
      bgColor={'#262B47'}
      h={'100%'}
      flexDir="column"
      justifyContent="space-between"
      py={'16px'}
      overflowY="auto"
      overflowX={'hidden'}
    >
      <Flex px="5%" flexDirection="column">
        <Flex ml={isExpanded ? '10px' : 2} justify={'space-between'} align={'center'} mb={2}>
          {renderLogo}
          {!hideToggleBtn && (
            <Button
              variant="plain"
              color={'gray.300'}
              onClick={onSidebarExpand}
              px="3.5"
              css={
                isExpanded
                  ? {
                      left: '1.2rem',
                    }
                  : {
                      right: '0.8rem',
                    }
              }
            >
              <Icon>{isExpanded ? <DoubleArrowLeftIcon mr={1} /> : <DoubleArrowRightIcon mr={2} />}</Icon>
            </Button>
          )}
        </Flex>
        <Flex flexDirection={'column'} justifyContent={'space-between'} mt={2} gap={10}>
          {user?.properties?.partner_id ? <PartnerOrgSelector isExpanded={isExpanded} /> : null}
          <Flex flexDirection={'column'} justifyContent={'space-between'}>
            {APP_ROUTES.map(
              (link: ModuleInfo) =>
                !link.isBottomToolbar && (
                  <ACLCheck key={link.name} role={link.minRole}>
                    <NavItem
                      title={link.name}
                      data-onboarding={link.name}
                      data-active={activeButton === link.name || undefined}
                      icon={link.icon}
                      onClick={() => handleRedirect(link)}
                      href={link.href}
                      isExpanded={isExpanded}
                      warningMessage={getTaskWarning(link.name)}
                    >
                      {link.name}
                    </NavItem>
                  </ACLCheck>
                )
            )}
          </Flex>
        </Flex>
      </Flex>
      <Flex px="5%" flexDirection={'column'}>
        {APP_ROUTES.map(
          (link: ModuleInfo) =>
            link.isBottomToolbar && (
              <ACLCheck key={link.name} role={link.minRole}>
                <NavItem
                  title={link.name}
                  data-active={activeButton === link.name || undefined}
                  icon={link.icon}
                  onClick={() => handleRedirect(link)}
                  isExpanded={isExpanded}
                  href={link.href}
                >
                  <Text color={'white'} lineClamp={1}>
                    {link.name}
                  </Text>
                </NavItem>
              </ACLCheck>
            )
        )}
        <MenuRoot positioning={{ placement: 'top' }}>
          <MenuTrigger asChild my={3} px={4}>
            <Button justifyContent="start" variant="plain" p="0" asChild>
              <Flex gap={4}>
                <AvatarGroup>
                  <Avatar w="6" h="6" src={user?.pictureUrl || ''} />
                </AvatarGroup>
                <Text lineClamp={1} color={'white'}>
                  {user?.firstName + ' ' + user?.lastName}
                </Text>
              </Flex>
            </Button>
          </MenuTrigger>
          <MenuContent>
            <MenuItem value="account" onClick={() => redirectToOrgPage(externalId)}>
              <FiUser />
              <Box flex="1">Account</Box>
            </MenuItem>
            <MenuItem value="logout" onClick={logoutFunction}>
              <FiLogOut />
              <Box flex="1">Logout</Box>
            </MenuItem>
          </MenuContent>
        </MenuRoot>
        {user?.properties?.partner_id ? (
          <PartnerOrgName isCollapsed={!isExpanded} />
        ) : (
          <OrganizationSelector isExpanded={isExpanded} />
        )}
      </Flex>
    </Flex>
  );
};
