import { Box, createListCollection, Flex, HStack, Input, Link, Stack, Text, VStack } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createRegistrationApi, REGISTRATION_STATE_KEY } from 'apis/registration-apis';
import { Button } from 'components/ui/button';
import { Checkbox } from 'components/ui/checkbox';
import DatePicker from 'components/ui/date-picker';
import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from 'components/ui/dialog';
import { Field } from 'components/ui/field';
import { NativeSelectField, NativeSelectRoot } from 'components/ui/native-select';
import { PasswordInput } from 'components/ui/password-input';
import { Radio, RadioGroup } from 'components/ui/radio';
import {
  CountrySelect,
  SelectContent,
  SelectItem,
  SelectRoot,
  SelectTrigger,
  SelectValueText,
  SubCategorySelectItem,
} from 'components/ui/select';
import { toaster } from 'components/ui/toaster';
import { Tooltip } from 'components/ui/tooltip';
import { NO_SALES_TAX_STATES } from 'constants/app-constants';
import { isBefore, parseISO, startOfMonth } from 'date-fns';
import { useFormik } from 'formik';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import { useStateOptions } from 'hooks/useStateOptions';
import useTracking from 'hooks/useTracking';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CountryCodeEnum, RegistrationsRegimeEnum, RegistrationStatus } from 'types/shared-types';
import { getFormattedDate } from 'utils/dates';
import { array, date, object, string } from 'yup';

const baseValidationSchema = object().shape({
  country_code: string().required('Country is required'),
  state_code: string().required('State is required'),
  registration_date: date().required('Registration date is required'),
  registration_email: string().email('Invalid email address').required('Email is required'),
  filing_frequency: string().required('Filing frequency is required'),
  filing_days: string().required('Filing days is required'),
  username: string()
    .nullable()
    .when('is_checkbox_checked', (isChecked: boolean[], schema) => {
      return isChecked[0] ? schema.nullable() : schema.required('Username is required');
    }),
  password_plain_text: string()
    .nullable()
    .when('is_checkbox_checked', (isChecked: boolean[], schema) => {
      return isChecked[0] ? schema.nullable() : schema.required('Password is required');
    }),
  security_questions: array().notRequired(),
  registrations_regime: string().when(['country_code', 'state_code'], {
    is: (country: string, state: string) => country === 'CA' && (state === 'FD' || state === 'QC'),
    then: schema => schema.required('Registration regime is required'),
    otherwise: schema => schema.notRequired(),
  }),
});

const SALES_TAX_FREE_STATES = ['DE', 'MT', 'NH', 'OR'];

export const RegistrationForm = () => {
  const contentRef = useRef<HTMLDivElement>(null);
  const { orgId } = useOrg();
  const queryClient = useQueryClient();
  const { handleFailNotification, handleSuccessNotification } = useHandleNotification();
  const [securityQuestions, setSecurityQuestions] = useState([{ question: '', answer: '' }]);
  const [showOptions, setShowOptions] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const isRequestRegistrationFormOpen = searchParams.get('importRegistration') === 'true';

  const { stateOptions, fetchStateOptions } = useStateOptions({ formatter: 'ca_taxJurisdiction' });

  const validationSchema = baseValidationSchema.shape({
    create_filing_option: showOptions
      ? string()
          .oneOf(['create_only_new_filing_periods', 'additionally_create_past_filing_periods'])
          .required('Filing option is required')
      : string().oneOf(['create_only_new_filing_periods', 'additionally_create_past_filing_periods']),
  });

  const mutation = useMutation({
    mutationFn: async (payload: any) => {
      const { data } = await createRegistrationApi(orgId, payload);
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [REGISTRATION_STATE_KEY] });
      onCloseModal();
      handleSuccessNotification('Registration added successfully.');
    },
    onError: (err: any) => {
      if (err && err.response?.status === 409) {
        toaster.create({
          title: 'Registration for state and country already exists',
          type: 'error',
        });
        return;
      }
      handleFailNotification(err);
    },
  });

  const formik = useFormik({
    initialValues: {
      country_code: 'US',
      state_code: '',
      state_name: '',
      registration_date: '',
      registration_email: '',
      filing_frequency: '',
      filing_days: '',
      status: RegistrationStatus.VALIDATING,
      username: '',
      password_plain_text: '',
      password_metadata_plain_text: '',
      create_filing_option: '',
      imported: true,
      is_checkbox_checked: false,
      registrations_regime: null,
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      let payload = { ...values } as any;
      if (SALES_TAX_FREE_STATES.includes(payload.state_code)) {
        toaster.create({
          title: `${payload.state_name} Doesn't have sales tax. Please choose other state.`,
          type: 'warning',
          duration: 5000,
        });
      } else {
        switch (values.create_filing_option) {
          case 'create_only_new_filing_periods':
            payload = {
              create_filings_from: getFormattedDate(new Date(), 'yyyy-MM-dd'),
              ...payload,
            };
            break;
          case 'additionally_create_past_filing_periods':
            payload = {
              create_filings_from: values.registration_date,
              ...payload,
            };
            break;
        }

        mutation.mutate(payload);
        track('registers jurisdiction', { jurisdiction: values.state_name });
      }
    },
  });

  useEffect(() => {
    if (formik.values.country_code) {
      fetchStateOptions(formik.values.country_code);
    }
  }, [formik.values.country_code, fetchStateOptions]);

  const showRegistrationRegime = useMemo(() => {
    return (
      formik.values.country_code === CountryCodeEnum.CA &&
      (formik.values.state_code === 'FD' || formik.values.state_code === 'QC')
    );
  }, [formik.values.country_code, formik.values.state_code]);

  const { track } = useTracking();

  const registrationRegimeCollection = useMemo(() => {
    return createListCollection({
      items: [
        { value: RegistrationsRegimeEnum.STANDARD, label: 'Standard' },
        {
          value: RegistrationsRegimeEnum.SIMPLIFIED,
          label: 'Simplified',
          description: 'The Simplified regime is relevant only for businesses outside Canada selling digital products.',
        },
      ],
    });
  }, []);
  const handleFilingChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const FilingOptions = [
      { value: 'MONTHLY', label: '20' },
      { value: 'QUARTERLY', label: '20' },
      { value: 'SEMI_ANNUALLY', label: '20' },
      { value: 'ANNUALLY', label: '20' },
    ];
    const selectedFilingFreq = event.target.value;
    formik.setFieldValue('filing_frequency', selectedFilingFreq);
    const selectedFiling = FilingOptions.find(filing => filing.value === selectedFilingFreq);

    if (selectedFiling) {
      formik.setFieldValue('filing_days', selectedFiling.label);
    } else {
      formik.setFieldValue('filing_days', '');
    }
  };

  const handleAddQuestion = () => {
    if (securityQuestions.length < 3) {
      setSecurityQuestions([...securityQuestions, { question: '', answer: '' }]);
    }
  };

  const handleQuestionChange = (index: number, field: 'question' | 'answer', value: string) => {
    const updatedQuestions = [...securityQuestions];
    updatedQuestions[index][field] = value;
    setSecurityQuestions(updatedQuestions);
    formik.setFieldValue('password_metadata_plain_text', JSON.stringify(updatedQuestions));
  };

  const closeModal = () =>
    setSearchParams(params => {
      params.delete('importRegistration');
      return params;
    });

  const onCloseModal = () => {
    formik.resetForm();
    setSecurityQuestions([{ question: '', answer: '' }]);
    setShowOptions(false);
    closeModal();
  };

  const checkIfDateBeforeCurrentMonth = (date: string) => {
    const parsedDate = parseISO(date);
    const startOfCurrentMonth = startOfMonth(new Date());
    const isBeforeCurrentMonth = isBefore(parsedDate, startOfCurrentMonth);
    setShowOptions(isBeforeCurrentMonth);
  };

  const statesCollection = useMemo(
    () =>
      createListCollection({
        items: stateOptions,
        isItemDisabled: ({ value }) => NO_SALES_TAX_STATES.includes(value),
      }),
    [stateOptions]
  );

  return (
    <DialogRoot
      scrollBehavior="inside"
      closeOnInteractOutside={false}
      size={'md'}
      open={isRequestRegistrationFormOpen}
      onOpenChange={({ open }) => {
        if (!open) {
          onCloseModal();
        }
      }}
    >
      <DialogBackdrop />
      <DialogContent ref={contentRef}>
        <DialogCloseTrigger />
        <DialogHeader>
          <DialogTitle>Import Registration</DialogTitle>
        </DialogHeader>
        <DialogBody>
          <Stack gap={'16px'}>
            <Text>Only import a Registration if you&apos;ve already registered outside Kintsugi</Text>
            <HStack align={'baseline'}>
              <Field label="Country" required>
                <CountrySelect
                  ref={contentRef}
                  value={formik.values.country_code ? [formik.values.country_code] : undefined}
                  onValueChange={({ value }) => {
                    formik.setFieldValue('country_code', value[0]);
                    formik.setFieldValue('state_code', '');
                  }}
                />
              </Field>
              <Field
                label="Jurisdiction"
                invalid={!!(formik.errors.state_code && formik.touched.state_code)}
                errorText={formik?.errors?.state_code?.toString()}
                required
              >
                <SelectRoot
                  collection={statesCollection}
                  value={formik.values.state_code ? [formik.values.state_code] : undefined}
                  onValueChange={({ value: itemValue }) => {
                    const item = statesCollection.items.find(({ value }) => value === itemValue[0]);

                    if (item) {
                      formik.setFieldValue('state_name', item.label);
                      formik.setFieldValue('state_code', item.value);
                    }
                  }}
                >
                  <SelectTrigger>
                    <SelectValueText />
                  </SelectTrigger>
                  <SelectContent portalRef={contentRef}>
                    {statesCollection.items.map(item => (
                      <Tooltip
                        key={item.value}
                        content={`Sales tax registration isn’t needed in ${item.label} because there’s no sales tax in this state.`}
                        openDelay={100}
                        showArrow
                        positioning={{ placement: 'bottom', offset: { mainAxis: 0, crossAxis: 5 } }}
                        disabled={!NO_SALES_TAX_STATES.includes(item.value)}
                      >
                        <Box cursor="not-allowed">
                          <SelectItem item={item}>
                            <Text>{item.label}</Text>
                          </SelectItem>
                        </Box>
                      </Tooltip>
                    ))}
                  </SelectContent>
                </SelectRoot>
              </Field>
            </HStack>

            {showRegistrationRegime && (
              <VStack alignItems={'flex-start'} width={'100%'}>
                <Field
                  label="Registration Regime"
                  invalid={!!(formik.errors.registrations_regime && formik.touched.registrations_regime)}
                  errorText={formik.errors.registrations_regime}
                  required
                  tooltip={`The 'Simplified' regime can be used only when you satisfy
certain conditions, such as not having a physical presence
in Canada and selling certain types of products. Under
this regime, you don't need to collect sales tax when
your customer provides GST/HST registration number.`}
                >
                  <SelectRoot
                    collection={registrationRegimeCollection}
                    value={formik.values.registrations_regime ? [formik.values.registrations_regime] : undefined}
                    onValueChange={({ value }) => {
                      formik.setFieldValue('registrations_regime', value[0]);
                    }}
                  >
                    <SelectTrigger>
                      <SelectValueText placeholder="Select" />
                    </SelectTrigger>
                    <SelectContent portalled={true} portalRef={contentRef}>
                      {registrationRegimeCollection.items.map(item => (
                        <SubCategorySelectItem tooltip={item.description} key={item.value} item={item}>
                          {item.label}
                        </SubCategorySelectItem>
                      ))}
                    </SelectContent>
                  </SelectRoot>
                </Field>
              </VStack>
            )}
            <HStack align={'baseline'}>
              <Field
                label="Registration Date"
                invalid={!!(formik.errors.registration_date && formik.touched.registration_date)}
                errorText={formik.errors.registration_date}
                required
              >
                <DatePicker
                  selected={formik.values.registration_date}
                  onChange={date => {
                    formik.setFieldValue('registration_date', date);
                    checkIfDateBeforeCurrentMonth(date);
                  }}
                />
              </Field>
              <Field
                label="Registration Email"
                invalid={!!(formik.errors.registration_email && formik.touched.registration_email)}
                errorText={formik.errors.registration_email}
                required
              >
                <Input
                  id="registration_email"
                  type="email"
                  name="registration_email"
                  value={formik.values.registration_email}
                  onChange={formik.handleChange}
                />
              </Field>
            </HStack>
            {showOptions && (
              <Field
                label="Select filings generation option :"
                invalid={!!(formik.errors.create_filing_option && formik.touched.create_filing_option)}
                errorText={formik.errors.create_filing_option}
                required={showOptions}
              >
                <RadioGroup name="create_filing_option" value={formik.values.create_filing_option}>
                  <Stack direction="column">
                    <Radio value={'create_only_new_filing_periods'} onChange={formik.handleChange}>
                      Create filings only for the upcoming periods
                    </Radio>
                    <Radio value={'additionally_create_past_filing_periods'} onChange={formik.handleChange}>
                      Create filings for all the periods from the registration date
                    </Radio>
                  </Stack>
                </RadioGroup>
              </Field>
            )}
            <Field
              label="Filing Frequency"
              invalid={!!(formik.errors.filing_frequency && formik.touched.filing_frequency)}
              errorText={formik.errors.filing_frequency}
              required
            >
              <NativeSelectRoot>
                <NativeSelectField
                  id="filing_frequency"
                  name="filing_frequency"
                  value={formik.values.filing_frequency}
                  onChange={handleFilingChange}
                >
                  <option>Select filing frequency</option>
                  <option value="MONTHLY">Monthly</option>
                  <option value="QUARTERLY">Quarterly</option>
                  <option value="SEMI_ANNUALLY">Semi-annually</option>
                  <option value="ANNUALLY">Annually</option>
                </NativeSelectField>
              </NativeSelectRoot>
            </Field>
            <HStack align={'baseline'} display={formik.values.is_checkbox_checked ? 'none' : 'flex'}>
              <Field
                label="Username"
                invalid={!!(formik.errors.username && formik.touched.username)}
                errorText={formik.errors.username}
                required={!formik.values.is_checkbox_checked}
                tooltip={`The username that is used to login this jurisdictions website, this could be your email address or a username.`}
              >
                <Input id="username" name="username" value={formik.values.username} onChange={formik.handleChange} />
              </Field>
              <Field
                label="Password"
                invalid={!!(formik.errors.password_plain_text && formik.touched.password_plain_text)}
                errorText={formik.errors.password_plain_text}
                required={!formik.values.is_checkbox_checked}
                tooltip={`The password you use to log into this jurisdiction's website.`}
              >
                <PasswordInput
                  id="password_plain_text"
                  name="password_plain_text"
                  value={formik.values.password_plain_text}
                  onChange={formik.handleChange}
                />
              </Field>
            </HStack>

            {!formik.values.is_checkbox_checked &&
              securityQuestions.map((question, index) => (
                <Box key={index}>
                  <Field key={`question_${index + 1}`} label={`Question ${index + 1}`}>
                    <Input
                      placeholder="Question"
                      id={`question_${index + 1}`}
                      value={question.question}
                      onChange={e => handleQuestionChange(index, 'question', e.target.value)}
                    />
                  </Field>
                  <Field key={`answer_${index + 1}`} mt={2}>
                    <Input
                      placeholder="Answer"
                      value={question.answer}
                      onChange={e => handleQuestionChange(index, 'answer', e.target.value)}
                    />
                  </Field>
                </Box>
              ))}
            {securityQuestions.length < 3 && !formik.values.is_checkbox_checked && (
              <Link variant="plain" paddingTop={'16px'} fontWeight={500} color={'#4285F4'} onClick={handleAddQuestion}>
                + &nbsp;Another Question
              </Link>
            )}
          </Stack>
          {/* put checkbox separately as entire text is clickable */}
          <Flex align="flex-start" gap={2} mt={4}>
            <Checkbox
              colorPalette={'blue'}
              size="sm"
              checked={formik.values.is_checkbox_checked}
              onCheckedChange={({ checked }) => formik.setFieldValue('is_checkbox_checked', checked)}
            ></Checkbox>
            <Text fontSize={'sm'} color="gray.900">
              I don&apos;t have the login credentials for this jurisdiction. I authorize Kintsugi to retrieve them on my
              behalf and initiate the Power of Attorney process, if required.
            </Text>
          </Flex>
        </DialogBody>
        <DialogFooter>
          <Flex gap={2}>
            <Button variant={'outline'} onClick={onCloseModal}>
              Cancel
            </Button>
            <Button
              variant={'solid'}
              colorPalette={'blue'}
              width={'90px'}
              loading={mutation.isPending}
              disabled={!formik.isValid || !formik.dirty}
              onClick={() => formik.handleSubmit()}
            >
              Save
            </Button>
          </Flex>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};
