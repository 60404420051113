import { Box, Flex, HStack, Skeleton, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import {
  getRelatedTransactionForId,
  getTransactionForId,
  RELATED_TRANSACTION_STATE_KEY,
  TRANSACTION_STATE_KEY,
} from 'apis/transaction-api';
import TransactionDetailsToolbar from 'app-header-toolbar/transaction-details-toolbar';
import AlertBanner from 'components/alert/alert';
import { useOrg } from 'hooks/useOrg';
import { useNavigate, useParams } from 'react-router-dom';
import { AddressInstance, ProcessingStatusEnum } from 'types/shared-types';

import { RelatedTransactionTable } from './related-transaction-table';
import { AddressesCards } from './transaction-details/address-cards';
import { TransactionDetailsHeaderLabel } from './transaction-details/header-label';
import { TransactionDetailAction } from './transaction-details/transaction-detail-action';
import { TransactionItemsTable } from './transaction-details/transaction-items';

const TRANSACTION_ITEM_TABLE_HEAD = ['PRODUCT', 'DESCRIPTION', 'QUANTITY', 'COLLECTED TAX', 'TAX LIABILITY', 'SALES'];

type TransactionDetailsProps = {
  transactionId?: string;
  onClick?: () => void;
};

export const TransactionDetails = ({ transactionId, onClick }: TransactionDetailsProps) => {
  const navigate = useNavigate();
  const { transactionId: idFromParams } = useParams<{ transactionId: string }>();
  const { orgId } = useOrg();
  const id = transactionId || idFromParams;
  const {
    isPending,
    data: transactionData,
    isError,
  } = useQuery({
    queryKey: [TRANSACTION_STATE_KEY, id],
    queryFn: async () => {
      const { data } = await getTransactionForId(orgId, id!);
      return data;
    },
    enabled: !!id,
  });

  const { isPending: isRelatedDataPending, data: relatedTransactionData } = useQuery({
    queryKey: [RELATED_TRANSACTION_STATE_KEY, id],
    queryFn: async () => {
      const { data } = await getRelatedTransactionForId(orgId, id!);
      return data;
    },
    enabled: !!id,
  });

  const handleOnclick = () => {
    if (onClick) {
      onClick();
    } else {
      navigate(-1); //navigate to collected transactions page or transaction page
    }
  };

  if (isPending || isRelatedDataPending) {
    return Array.from({ length: 10 }, (_, index) => (
      <Box key={index} mt={6}>
        <Skeleton height="30px" width="full" />
      </Box>
    ));
  }

  if (!transactionData || !relatedTransactionData || !id) {
    return null;
  }

  if (isError) {
    console.log('error while fetching data');
    return null;
  }

  const billingAddress = transactionData?.addresses?.find((address: AddressInstance) => address.type === 'BILL_TO');
  const shippingAddress = transactionData?.addresses?.find((address: AddressInstance) => address.type === 'SHIP_TO');
  const transactionItems = transactionData?.transaction_items;
  const subtotal = {
    totalAmount: Number(transactionData?.total_amount),
    totalTaxAmount: Number(transactionData?.total_tax_liability_amount),
  };

  return (
    <Flex flexDir={'column'} w={{ base: 'max', md: 'full', lg: 'full' }}>
      <HStack gap={1} justifyContent={'space-between'}>
        <TransactionDetailsHeaderLabel
          data={transactionData}
          isPending={isPending}
          isError={isError}
          onClick={handleOnclick}
        />
        <TransactionDetailsToolbar data={transactionData} isPending={isPending} isError={isError} />
      </HStack>
      <Flex gap={4} mt={'32px'} flexDir={'column'} w={'full'}>
        {transactionData?.processing_status === ProcessingStatusEnum.QUEUED && (
          <AlertBanner
            message="We're processing this recently imported transaction. Please check back in a few minutes for the latest tax numbers."
            width={'839px'}
          />
        )}
        {(billingAddress || shippingAddress) && (
          <AddressesCards billing={billingAddress || null} shipping={shippingAddress || null} />
        )}

        {relatedTransactionData && Object.keys(relatedTransactionData).length > 0 && (
          <>
            <Text fontSize={'18px'} fontWeight={500}>
              Related Transaction
            </Text>
            <RelatedTransactionTable data={relatedTransactionData} />
          </>
        )}

        <Flex height={'28px'} justifyContent={'space-between'}>
          <Text fontSize="large" fontWeight="medium" paddingTop={'10px'}>
            Transaction Items
          </Text>
          <Box zIndex={5} width={'20px'} justifyContent={'flex-end'}>
            <TransactionDetailAction
              customerId={transactionData?.customer_id}
              transactionId={id}
              transactionDate={transactionData?.date}
            />
          </Box>
        </Flex>

        {transactionData?.exemptions?.length > 0 && (
          <AlertBanner
            message="This transaction has a one-time exemption certificate applied. "
            width={{ xl: '75%' }}
          />
        )}

        <TransactionItemsTable
          captions={TRANSACTION_ITEM_TABLE_HEAD}
          data={transactionItems}
          subtotal={subtotal}
          taxLiabilitySource={transactionData?.tax_liability_source}
        />
      </Flex>
    </Flex>
  );
};
