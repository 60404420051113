import { array, date, InferType, object, string } from 'yup';

export const UpdateRegistrationValidationSchema = object().shape({
  country_code: string().required('Country is required'),
  state_code: string().required('State is required'),
  registration_date: date().required('Registration date is required'),
  registration_email: string().email('Invalid email address').required('Registration email is required'),
  filing_frequency: string().required('Filing frequency is required'),
  filing_days: string(),
  username: string().required('Username is required'),
  password_plain_text: string().required('Password is required'),
  security_questions: array().notRequired(),
});

export type UpdateRegistrationResSchema = InferType<typeof UpdateRegistrationValidationSchema>;
