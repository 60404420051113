import { Box, BoxProps, Flex, FlexProps, SkipNavContent, SkipNavLink } from '@chakra-ui/react';
import { useAuthInfo } from '@propelauth/react';
import { ErrorBoundary, ErrorFallback } from 'components/error-fallback';
import IntercomWidget from 'components/intercom';
import { OnboardingStepper } from 'components/onboarding-stepper';
import { Sidebar } from 'components/Sidebar';
import { APP_ROUTES } from 'constants/app-constants';
import { ContentComponent, OnboardingStatus, onboardingSteps, TourProvider } from 'hooks/useOnboarding';
import { useOrg } from 'hooks/useOrg';
import usePageTracking from 'hooks/usePageTracking';
import useUpdateDocTitle from 'hooks/useUpdateDocTitle';
import { CreateOrgModal } from 'pages/Configuration/components/org-settings/create-org-modal';
import PartnerOrg from 'pages/PartnerOrg';
import { forwardRef, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useLocation, useSearchParams } from 'react-router-dom';

import AppDrawers from './app-drawers';
import { AppOnboarding } from './app-onboarding';
import AppPopups from './app-popups';

const App = ({ children, ...rest }: FlexProps) => (
  <Flex h="100vh" {...rest}>
    {children}
  </Flex>
);
const AppBody = ({ children, ...rest }: BoxProps) => (
  <Box h="100%" overflow="auto" {...rest}>
    {children}
  </Box>
);
const AppContent = forwardRef<HTMLDivElement, BoxProps>(({ children, ...rest }, ref) => (
  <Box pb="37px" {...rest} ref={ref}>
    {children}
  </Box>
));
AppContent.displayName = 'AppContent';

const fallBack = () => <ErrorFallback />;

type UserMetaData = {
  onboarding_status: OnboardingStatus;
};

export const AppLayoutMd = (props: PropsWithChildren): React.ReactNode => {
  const { user } = useAuthInfo();
  const { orgId, isTest } = useOrg();
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const location = useLocation();
  const scrollContainer = useRef<HTMLDivElement>(null);
  const [searchParams] = useSearchParams();
  const switchOrgForShopifyURL = searchParams.get('shop') ?? searchParams.get('shopURL');

  usePageTracking();
  useUpdateDocTitle(APP_ROUTES);
  useHotkeys('[', () => setIsExpanded(!isExpanded), [isExpanded]);

  const userMetaData: UserMetaData = (user?.properties?.metadata as UserMetaData) || {
    onboarding_status: OnboardingStatus.NOT_STARTED,
  };

  useEffect(() => {
    if (scrollContainer?.current) {
      scrollContainer.current.scrollTop = 0;
    }
  }, [location]);

  const sidebarWidth = isExpanded ? '250px' : '4rem';
  // not to render app content until org selected

  if (!orgId) {
    return (
      <App>
        <ErrorBoundary fallback={fallBack} onError={(error: Error) => console.error(error)}>
          <Box h={'100vh'} w={sidebarWidth}>
            <Sidebar isExpanded={isExpanded} onSidebarExpand={() => setIsExpanded(!isExpanded)} />
          </Box>
          <AppBody px={4} w="100%">
            {!!user?.properties?.partner_id && (
              <AppContent ref={scrollContainer} height={'100%'}>
                <PartnerOrg />
                <CreateOrgModal />
              </AppContent>
            )}
          </AppBody>
          <Box position="fixed" bottom="10px" right="10px" padding="10px"></Box>
        </ErrorBoundary>
      </App>
    );
  }

  return (
    <>
      <SkipNavLink zIndex={9999}>Skip to content</SkipNavLink>
      <App>
        <TourProvider
          onClickMask={() => {
            // to override default mask click
          }}
          steps={onboardingSteps}
          defaultOpen={
            !switchOrgForShopifyURL &&
            (userMetaData.onboarding_status === undefined ||
              userMetaData.onboarding_status === OnboardingStatus.NOT_STARTED)
          }
          showDots={false}
          showBadge={false}
          disableInteraction={true}
          disableKeyboardNavigation={true}
          disableFocusLock={true}
          ContentComponent={ContentComponent}
          styles={{ popover: base => ({ ...base, padding: '1rem', borderRadius: '4px' }) }}
        >
          <AppOnboarding status={userMetaData.onboarding_status} />
          <Box h={'100vh'} w={sidebarWidth}>
            <Sidebar isExpanded={isExpanded} onSidebarExpand={() => setIsExpanded(!isExpanded)} />
          </Box>
          <AppBody px={6} w="100%" borderTop={isTest ? '7px solid #DD6B20' : 'unset'}>
            <SkipNavContent />
            <AppContent ref={scrollContainer}>{props.children}</AppContent>
          </AppBody>
          <AppPopups />
          <AppDrawers />

          <Flex
            zIndex={1000}
            position="fixed"
            width={{ base: '13.25rem', sm: '100%', md: '13.25rem', lg: '13.25rem' }}
            left={`calc(${sidebarWidth} - 14px)`}
            bottom="10px"
            align={'center'}
          >
            <OnboardingStepper />
          </Flex>
          <Flex
            zIndex={1000}
            flexDir={'column'}
            position="fixed"
            bottom="10px"
            right="16px"
            padding="10px"
            gap={4}
            align={'center'}
          >
            <IntercomWidget />
          </Flex>
        </TourProvider>
      </App>
    </>
  );
};

export default AppLayoutMd;
