import { CreditsListType, CreditsPayload } from 'types/credits';
import { ApiResponse, TableFilter } from 'types/shared-types';

import api from './api';

export const CREDITS_SERVICE_QUERY_KEYS = {
  base: ['credits'] as const,
  list: (orgId: string, registrationId: string) =>
    [...CREDITS_SERVICE_QUERY_KEYS.base, orgId, registrationId, 'list'] as const,
};

export const getCredits = async (orgId: string, registrationId: string, params: TableFilter) => {
  const response = await api.get<ApiResponse<CreditsListType>>(`/v1/credits`, {
    headers: {
      'x-organization-id': orgId,
    },
    params: {
      ...params,
      registration_id: registrationId,
    },
  });
  return response.data;
};

export const doCreateCredits = async (orgId: string, payload: CreditsPayload) => {
  return await api.post(`/v1/credits`, payload, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};
