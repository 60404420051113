import { ApideckVault } from '@apideck/vault-js';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  CONNECTIONS_STATE_KEY,
  createApiDeckConnection,
  deleteApiDeckConnection,
  updateApiDeckConnections,
} from 'apis/connections';
import { useOrgDetailsQuery } from 'apis/organizations-apis';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import useTracking from 'hooks/useTracking';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { BusinessAddressPrompt } from '../../update-address-prompt';
import { ItemCard } from '../item-card';
import { QuickbooksIcon } from './icons/quickbooks';

export default function QuickbooksTab({ isConnected = false }: { isConnected?: boolean }) {
  const { orgId } = useOrg();
  const { track } = useTracking();
  const [quickBooksData, setQuickBooksData] = useState({
    consumer_id: '',
  });
  const { handleFailNotification } = useHandleNotification();
  const { handleSuccessNotification } = useHandleNotification();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [isBusinessAddressPromptOpen, setIsBusinessAddressPromptOpen] = useState(false);
  const [isQuickBookLoading, setIsQuickBookLoading] = useState(false);

  const { data } = useOrgDetailsQuery(orgId);
  const isAddressMissing = data ? !data.company_country_code || !data.company_postal_code : true;

  const quickBooksMutation = useMutation({
    mutationFn: () => {
      return updateApiDeckConnections(quickBooksData.consumer_id, orgId, 'ACTIVE');
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [CONNECTIONS_STATE_KEY] });
      handleSuccessNotification('QuickBooks Connected SuccessFully.');
      navigate('/source');
    },
    onError: error => {
      handleFailNotification(error);
    },
  });
  const onQuickBooksAuthenticated = async (connection: any) => {
    // Reference on Apideck's connection state:
    // https://developers.apideck.com/apis/vault/reference#section/Unify-and-Proxy-integration

    // callable: the connection is authorized and ready for next steps
    if (connection.state === 'callable') {
      quickBooksMutation.mutate();
      track('connects integration', { integration: 'quickbooks', status: 'success' });
    }

    // added: the connection was added but not yet authorized
    if (connection.state === 'added' && quickBooksData.consumer_id) {
      updateApiDeckConnections(quickBooksData.consumer_id, orgId, 'INACTIVE');
      track('connects integration', { integration: 'quickbooks', status: 'failed' });
    }
  };

  const deleteApiDeckConnectionMutation = useMutation<unknown, unknown, string>({
    mutationFn: (connection_id: string) => {
      return deleteApiDeckConnection(connection_id, orgId);
    },
    retry: (failureCount, error: any) => {
      if (error?.response?.status === 403 && failureCount < 3) {
        return true;
      }
      return false;
    },
    retryDelay: attemptIndex => {
      return Math.min(1000 * Math.pow(2, attemptIndex + 1), 8000);
    },
  });

  const handleApiDeckIntegration = async (serviceId: string) => {
    try {
      setIsQuickBookLoading(true);
      const response = await createApiDeckConnection(orgId, serviceId, {});
      const responseData = response.data;
      const sessionToken = responseData.token;
      const connection_id = responseData.connection_id;
      setQuickBooksData({ consumer_id: connection_id });

      ApideckVault.open({
        token: sessionToken,
        unifiedApi: 'accounting',
        serviceId: serviceId,
        onConnectionChange: onQuickBooksAuthenticated,
        onClose: () => {
          deleteApiDeckConnectionMutation.mutate(connection_id, {
            onSuccess: () => {
              setQuickBooksData({ consumer_id: '' });
            },
          });
        },
      });
      setIsQuickBookLoading(false);
    } catch (error) {
      setIsQuickBookLoading(false);
      console.error(error);
    }
  };

  return (
    <>
      <ItemCard
        isConnected={isConnected}
        title={'Quickbooks'}
        description={'Accounting Software & Solutions'}
        icon={<QuickbooksIcon />}
        onConnect={() =>
          isAddressMissing ? setIsBusinessAddressPromptOpen(true) : handleApiDeckIntegration('quickbooks')
        }
        btnProps={{ loading: isQuickBookLoading, colorPalette: 'lightGray' }}
      />
      <BusinessAddressPrompt
        isOpen={isBusinessAddressPromptOpen}
        onClose={() => setIsBusinessAddressPromptOpen(false)}
        onSuccess={() => handleApiDeckIntegration('quickbooks')}
      />
    </>
  );
}
