import { Box, Flex, Heading, HStack, IconButton, Stack, Text } from '@chakra-ui/react';
import { useGetRegistrationById } from 'apis/registration-apis';
import { ArrowLeft } from 'components/icons';
import { DrawerBackdrop, DrawerBody, DrawerContent, DrawerHeader, DrawerRoot } from 'components/ui/drawer';
import { useOrg } from 'hooks/useOrg';
import { CountryCodeEnum, RegistrationsRegimeEnum } from 'types/shared-types';
import { getCountryNameByCode } from 'utils';
import { toDateShort } from 'utils/dates';
import { getHumanReadableString } from 'utils/enum-helpers';
import { formatCurrency } from 'utils/utils';

import { RegistrationDetailsTable } from './registration-details';

interface RegistrationDetailsDrawerProps {
  onClose: () => void;
  registrationId: string;
}

export function RegistrationDetailsDrawer({ onClose, registrationId }: RegistrationDetailsDrawerProps) {
  const { orgId } = useOrg();

  const { data: registrationData } = useGetRegistrationById({ orgId, registrationId });

  if (!registrationData) {
    return null;
  }

  const {
    country_code,
    state_name,
    state_code,
    registrations_regime,
    filing_frequency,
    status,
    amount_fees,
    registration_date,
    registration_email,
    comment,
  } = registrationData;

  const showRegistrationRegime = country_code === CountryCodeEnum.CA && (state_code === 'FD' || state_code === 'QC');
  const isSimplifiedRegime = registrations_regime === RegistrationsRegimeEnum.SIMPLIFIED;

  const getStateName = (state_code: string) => {
    return state_code === 'FD' ? 'Canada (Federal)' : state_name;
  };

  const registrationDetailsTableData = [
    { label: 'Country', value: getCountryNameByCode(country_code) },
    { label: 'Jurisdiction', value: getStateName(state_code) },
    {
      label: 'Registration Regime',
      value: getHumanReadableString(registrations_regime),
      display: showRegistrationRegime,
      tooltip: isSimplifiedRegime
        ? "The 'Simplified' regime can be used only when you satisfy certain conditions, such as not having a physical presence in Canada and selling certain types of products. Under this regime, you don't need to collect sales tax when your customer provides GST/HST registration number."
        : undefined,
    },
    { label: 'Registration Date', value: toDateShort(registration_date) },
    { label: 'Registration Email', value: registration_email },
    { label: 'Filing Frequency', value: getHumanReadableString(filing_frequency) },
    { label: 'Registration Fees', value: formatCurrency(amount_fees) },
    { label: 'Status', value: getHumanReadableString(status) },
  ];

  return (
    <DrawerRoot open={!!registrationId} size="md" placement="end" onInteractOutside={onClose}>
      <DrawerBackdrop />
      <DrawerContent>
        <DrawerHeader pl={2} pt={3} pb={2}>
          <Flex alignItems="center" justifyContent="space-between" flexWrap="wrap" width="100%">
            <HStack gap={2}>
              <IconButton rounded={'sm'} size="xs" variant={'ghost'} aria-label="back-to-filings" onClick={onClose}>
                <ArrowLeft />
              </IconButton>
              <Box>
                <Heading size="xl" fontWeight="medium">
                  Registration Details
                </Heading>
              </Box>
            </HStack>
          </Flex>
        </DrawerHeader>
        <DrawerBody>
          <RegistrationDetailsTable data={registrationDetailsTableData} />
          {comment && (
            <Stack mt={4}>
              <Text fontWeight="500" fontSize={'16px'}>
                Comment
              </Text>
              <Box border="1px solid" borderColor="gray.50" borderRadius="4px" p={2}>
                {comment}
              </Box>
            </Stack>
          )}
        </DrawerBody>
      </DrawerContent>
    </DrawerRoot>
  );
}
