import { Flex, HStack, Icon, Table, Text } from '@chakra-ui/react';
import { InfoFilled } from 'components/icons';
import { Tooltip } from 'components/ui/tooltip';
import React from 'react';
import { MdInfo } from 'react-icons/md';

type TableRow = {
  label: string;
  value: string | number | null | undefined;
  info?: string;
  display?: boolean;
  tooltip?: string;
};

type TableProps = {
  data: TableRow[];
};

export const RegistrationDetailsTable: React.FC<TableProps> = ({ data }) => (
  <Flex justify="center" border={'1px solid'} borderColor={'gray.50'} borderRadius={'4px'} px={4} pt={2}>
    <Table.Root>
      <Table.Body>
        {data.map(({ label, value, info, display = true, tooltip }, index) => (
          <Table.Row key={label} px={2}>
            <Table.Cell
              width={'100%'}
              display={display ? 'table-cell' : 'none'}
              px={0}
              borderBottom={index === data.length - 1 ? 'none' : '1px solid'}
              borderColor={'gray.50'}
            >
              <Flex justifyContent={'space-between'}>
                <HStack gap={1}>
                  <Text>{label}</Text>
                  {tooltip && (
                    <Tooltip content={tooltip} positioning={{ placement: 'bottom-end' }}>
                      <Icon w={4} h={4}>
                        <InfoFilled w="4" h="4" />
                      </Icon>
                    </Tooltip>
                  )}
                </HStack>
                <Text>
                  {info && (
                    <Tooltip content={info} positioning={{ placement: 'bottom-start' }}>
                      <Icon w={4} h={4} ml={1}>
                        <MdInfo />
                      </Icon>
                    </Tooltip>
                  )}
                </Text>
                <Text fontWeight={500}>{value}</Text>
              </Flex>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table.Root>
  </Flex>
);
