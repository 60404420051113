import { Text, VStack } from '@chakra-ui/react';
import { useOrg } from 'hooks/useOrg';

type PartnerOrgNameProps = {
  isCollapsed?: boolean;
};

export const PartnerOrgName = ({ isCollapsed = false }: PartnerOrgNameProps) => {
  const { name } = useOrg();

  if (isCollapsed) {
    return (
      <Text
        color="#fff"
        fontSize={'sm'}
        px={2}
        py={2}
        borderRadius={'4px'}
        border={'1px solid'}
        borderColor={'gray.800'}
        width={'45px'}
        truncate
        mx={2}
      >
        {name}
      </Text>
    );
  }

  return (
    <VStack px={3} py={2} borderRadius={'4px'} bgColor={'gray.800'} alignItems={'flex-start'} gap={1} mx={2}>
      <Text lineClamp={1} color="#fff" title={name} fontSize={'14px'} truncate>
        {name}
      </Text>
      <Text fontSize={'xs'} bgColor="gray.700" color={'white'} borderRadius={'1px'} px={1}>
        Current Organization
      </Text>
    </VStack>
  );
};
