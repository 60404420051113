export const colors = {
  transparent: { value: 'transparent' },
  current: { value: 'currentColor' },
  black: { value: '#09090B' },
  white: { value: '#FFFFFF' },
  gray: {
    50: { value: '#EFEFF3' },
    100: { value: '#cfd0d8' },
    200: { value: '#b7bac5' },
    300: { value: '#a0a4b2' },
    400: { value: '#8a8e9f' },
    500: { value: '#74798d' },
    600: { value: '#5f647b' },
    700: { value: '#4b5169' },
    800: { value: '#383d58' },
    900: { value: '#262B47' },
  },
  primary: {
    50: { value: '#FFF8EC' },
    100: { value: '#FFE9C7' },
    200: { value: '#FFC976' },
    300: { value: '#FFC15D' },
    400: { value: '#FFB940' },
    500: { value: '#FFB000' },
    600: { value: '#DE9900' },
    700: { value: '#BE8200' },
    800: { value: '#805600' },
    900: { value: '#2E1C00' },
  },
  secondary: {
    50: { value: '#ECF3FE' },
    100: { value: '#D9E7FD' },
    200: { value: '#B3CEFB' },
    300: { value: '#8EB6F8' },
    400: { value: '#689DF6' },
    500: { value: '#4285F4' },
    600: { value: '#356AC3' },
    700: { value: '#285092' },
    800: { value: '#1A3562' },
    900: { value: '#070D18' },
  },
  red: {
    50: { value: '#FFF5F5' },
    100: { value: '#FED7D7' },
    200: { value: '#FEB2B2' },
    300: { value: '#FC8181' },
    400: { value: '#F56565' },
    500: { value: '#E53E3E' },
    600: { value: '#C53030' },
    700: { value: '#9B2C2C' },
    800: { value: '#822727' },
    900: { value: '#63171B' },
  },
  orange: {
    50: { value: '#FFFAF0' },
    100: { value: '#FEEBCB' },
    200: { value: '#FBD38D' },
    300: { value: '#F6AD55' },
    400: { value: '#ED8936' },
    500: { value: '#DD6B20' },
    600: { value: '#C05621' },
    700: { value: '#9C4221' },
    800: { value: '#7B341E' },
    900: { value: '#652B19' },
  },
  green: {
    50: { value: '#F0FFF4' },
    100: { value: '#C6F6D5' },
    200: { value: '#9AE6B4' },
    300: { value: '#68D391' },
    400: { value: '#48BB78' },
    500: { value: '#38A169' },
    600: { value: '#25855A' },
    700: { value: '#276749' },
    800: { value: '#22543D' },
    900: { value: '#1C4532' },
  },
  purple: {
    50: { value: '#A373EB' },
  },
  lightGray: {
    50: { value: '#f9f9fa' },
    100: { value: '#f5f5f8' },
    200: { value: '#f4f4f7' },
    300: { value: '#f2f2f5' },
    400: { value: '#f1f1f4' },
    500: { value: '#EFEFF3' },
    600: { value: '#cfd0d8' },
    700: { value: '#b7bac5' },
    800: { value: '#A7A7AA' },
    900: { value: '#78787a' },
  },
};
