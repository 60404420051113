import { useAuthInfo } from '@propelauth/react';
import * as Sentry from '@sentry/react';
import AppRedirectorPage from 'app/app-redirector';
import StripeAppInstallerPage from 'app/stripe-app-installer-with-org';
import { ErrorFallback, withErrorBoundary } from 'components/error-fallback';
import useTracking from 'hooks/useTracking';
import AddressesPage from 'pages/Addresses';
import ConfigurationPage from 'pages/Configuration';
import { StableForm } from 'pages/Configuration/components/communications/stable-form';
import CustomersPage from 'pages/Customers';
import { CustomerDetails } from 'pages/Customers/components/customer-details';
import DashboardPage from 'pages/Dashboard';
import BlankAddressTransactions from 'pages/Dashboard/components/BlankAddressTransactions';
import ExemptionsPage from 'pages/Exemptions';
import FilingPage from 'pages/Filing';
import ImportPage from 'pages/Import';
import IntegrationsPage from 'pages/Import/components/integrations';
import NexusPage from 'pages/Nexus';
import CollectedTransactionsPage from 'pages/Nexus/components/collected-transactions';
import PartnerOrgsPage from 'pages/PartnerOrg';
import Presence from 'pages/Presence';
import ProductsPage from 'pages/Products';
import RegistrationsPage from 'pages/Registrations';
import SupportsPage from 'pages/Supports';
import ToolsAndGuidePage from 'pages/ToolsAndGuide';
import { TransactionDetails } from 'pages/Transactions/components/transaction-details';
import TransactionsPage from 'pages/Transactions/transaction';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import ShopifyPage from 'shopify';
import ConnectShopifyPage from 'shopify/ConnectShopify';

import { BLANK_ADDRESSES, NEXUS_ROUTES, PARTNER_ORG, TOOLS_AND_GUIDE } from '../constants/AppRoutes';
import { AppLayout } from './app-layout';

const fallback = () => <ErrorFallback />;
const errorBoundaryOptions = { fallback, onerror: (error: Error) => console.error(error) };
const Dashboard = withErrorBoundary(DashboardPage, errorBoundaryOptions);
const Settings = withErrorBoundary(ConfigurationPage, errorBoundaryOptions);
const Customers = withErrorBoundary(CustomersPage, errorBoundaryOptions);
const Transactions = withErrorBoundary(TransactionsPage, errorBoundaryOptions);
const Registrations = withErrorBoundary(RegistrationsPage, errorBoundaryOptions);
const Nexus = withErrorBoundary(NexusPage, errorBoundaryOptions);
const CollectedTransactions = withErrorBoundary(CollectedTransactionsPage, errorBoundaryOptions);
const Filing = withErrorBoundary(FilingPage, errorBoundaryOptions);
const ShopifyLanding = withErrorBoundary(ShopifyPage, errorBoundaryOptions);
const ConnectShopify = withErrorBoundary(ConnectShopifyPage, errorBoundaryOptions);
const ToolsAndGuide = withErrorBoundary(ToolsAndGuidePage, errorBoundaryOptions);
const Products = withErrorBoundary(ProductsPage, errorBoundaryOptions);
const Import = withErrorBoundary(ImportPage, errorBoundaryOptions);
const Exemptions = withErrorBoundary(ExemptionsPage, errorBoundaryOptions);
const BulkCleanupAddresses = withErrorBoundary(AddressesPage, errorBoundaryOptions);
const AppRedirector = withErrorBoundary(AppRedirectorPage, errorBoundaryOptions);
const Supports = withErrorBoundary(SupportsPage, errorBoundaryOptions);
const PartnerOrg = withErrorBoundary(PartnerOrgsPage, errorBoundaryOptions);
const StripeAppInstaller = withErrorBoundary(StripeAppInstallerPage, errorBoundaryOptions);
const Integrations = withErrorBoundary(IntegrationsPage, errorBoundaryOptions);

const AppRoutes = (): React.ReactNode => {
  const { identifyUser } = useTracking();
  const { user } = useAuthInfo();

  useEffect(() => {
    if (user) {
      try {
        identifyUser({ email: user.email, id: user.userId });
        Sentry.setUser({ email: user.email, id: user.userId });
      } catch (error) {
        console.error('Failed to identify user:', error);
        Sentry.captureException(error);
      }
    }
  }, [user?.userId]);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<AppLayout />}>
          <Route index element={<Dashboard />} />
          {/* Redirect from base configurations path to default tab */}
          <Route path="/configurations" element={<Navigate to="/configurations/organization-details" replace />} />
          <Route path="/configurations/:tab?" element={<Settings />} />
          <Route path="/customers" element={<Customers />} />
          <Route path="/customers/:customerId" element={<CustomerDetails />} />
          <Route path="/transactions" element={<Transactions />} />
          <Route path="/transactions/:transactionId" element={<TransactionDetails />} />
          <Route path={NEXUS_ROUTES.EXPOSED} element={<Nexus />} />
          <Route path={NEXUS_ROUTES.NOT_EXPOSED} element={<Nexus />} />
          <Route path="/filing" element={<Filing />} />
          <Route path="/registrations" element={<Registrations />} />
          <Route path="/source" element={<Import />} />
          <Route path="/integrations" element={<Integrations />} />
          <Route path="/shopify" element={<ShopifyLanding />} />
          <Route path="/connect/shopify" element={<ConnectShopify />} />
          <Route path={PARTNER_ORG} element={<PartnerOrg />} />
          <Route path="/stableform" element={<StableForm />} />
          <Route path={TOOLS_AND_GUIDE} element={<ToolsAndGuide />} />
          <Route path="/products" element={<Products />} />
          <Route path="/exemptions" element={<Exemptions />} />
          <Route path="/presence" element={<Presence />} />
          <Route path="/invalid-addresses" element={<BulkCleanupAddresses />} />
          <Route path={BLANK_ADDRESSES} element={<BlankAddressTransactions />} />
          <Route path={NEXUS_ROUTES.COLLECTED_TRANSACTIONS} element={<CollectedTransactions />} />
        </Route>
        <Route path="/supports" element={<Supports />} />
        <Route path="/redirect" element={<AppRedirector />} />
        <Route path="/stripe-app" element={<StripeAppInstaller />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
};

export default withErrorBoundary(AppRoutes, errorBoundaryOptions);
