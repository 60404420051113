import { defineSlotRecipe } from '@chakra-ui/react';
import { checkboxAnatomy } from '@chakra-ui/react/anatomy';

export const checkboxSlotRecipe = defineSlotRecipe({
  slots: checkboxAnatomy.keys(),
  base: {
    label: {
      color: '{colors.gray.900}',
      _disabled: {
        color: '{colors.gray.300}',
      },
    },
  },
  variants: {
    variant: {
      solid: {
        control: {
          borderColor: '{colors.gray.700}',
          borderWidth: '2px',
          _disabled: {
            borderColor: '{colors.gray.300}',
          },
        },
      },
    },
  },
});
