import { useAuthInfo } from '@propelauth/react';
import getValue from 'lodash/get';

import { StripeAppConnector } from './stripe-app-connector';
import { StripeKeyConnector } from './stripe-key-connector';

const StripeTab = ({ isConnected }: { isConnected?: boolean }) => {
  const { user } = useAuthInfo();
  const stripeMode = getValue(user, 'properties.metadata.stripe.mode');
  if (stripeMode === 'key') {
    return <StripeKeyConnector isConnected={isConnected} />;
  }
  return <StripeAppConnector isConnected={isConnected} />;
};

export default StripeTab;
