import { Box, Button, Center, createListCollection, Flex, Text, VStack } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { handleApiErrorMessage } from 'apis/api';
import { getOrganizations } from 'apis/organizations-apis';
import { KintsugiLogoWithText } from 'components/icons';
import { Radio, RadioGroup } from 'components/ui/radio';
import { SelectContent, SelectItem, SelectRoot, SelectTrigger, SelectValueText } from 'components/ui/select';
import { useOrg } from 'hooks/useOrg';
import { useTableFilters } from 'hooks/useTableFilters';
import { getTokenIsTooLong } from 'hooks/useTrpcWithPropelAuth';
import { useMemo, useState } from 'react';
import { generateState, prepareStripInstallLink } from 'utils/utils';

enum Mode {
  LIVE = 'live',
  TEST = 'test',
}
const STRIPE_LIVE_INSTALL_LINK = import.meta.env.VITE_STRIPE_LIVE_INSTALL_LINK;
const STRIPE_TEST_INSTALL_LINK = import.meta.env.VITE_STRIPE_TEST_INSTALL_LINK;

const StripeAppInstallWithOrgSelector = () => {
  const { orgId } = useOrg();
  const { page, size, query } = useTableFilters({ size: 100 });
  const { data, isPending } = useQuery({
    queryKey: ['orgs', page, size, query],
    queryFn: async () => {
      const { data } = await getOrganizations(orgId, { page, size, query });
      return data;
    },
  });
  const [selectedMode, setSelectedMode] = useState<Mode | null>(null);
  const [selectedOrgId, setSelectedOrgId] = useState<string>();

  const tokenIsTooLong = getTokenIsTooLong();

  const orgCollections = useMemo(() => {
    return createListCollection({
      items: data?.items || [],
      itemToString: ({ name }) => name || '',
      itemToValue: ({ external_id }) => external_id,
    });
  }, [data]);

  const handleInstall = () => {
    const state = generateState();
    if (selectedMode && selectedOrgId) {
      const preparedLink = prepareStripInstallLink({
        link: selectedMode === Mode.LIVE ? STRIPE_LIVE_INSTALL_LINK : STRIPE_TEST_INSTALL_LINK,
        selectedOrgId,
        mode: selectedMode,
        state: state,
      });
      window.location.href = preparedLink;
    } else {
      handleApiErrorMessage({
        title: 'No Mode or Org Id selected',
        message: 'There is no mode or organization id selected!',
      });
    }
  };

  return (
    <Flex height="100dvh" colorPalette="blue">
      <Flex
        w={'5/12'}
        bg="gray.900"
        color="white"
        direction="column"
        align="center"
        justify="center"
        display={{ base: 'none', md: 'flex' }}
      >
        <KintsugiLogoWithText w={'240px'} h={'48px'} />
        <Text mt={2} textAlign="center">
          Sales tax automation, nexus tracking, registrations, filings,
          <br /> and tax calculator
        </Text>
      </Flex>

      <Flex flex="1" justify="center" color="#262B47">
        <Box p={8} width="450px" mt={16}>
          <Center display={{ base: 'flex', md: 'none' }} mb={4}>
            <KintsugiLogoWithText w={'240px'} h={'48px'} fill={'#262B47'} />
          </Center>
          <Text fontSize="lg" mb={2}>
            Choose Your Mode
          </Text>
          <RadioGroup
            disabled={tokenIsTooLong}
            value={selectedMode ?? ''}
            onValueChange={({ value }) => setSelectedMode(value as Mode)}
          >
            <VStack gap={4} align="start">
              <Radio value={Mode.LIVE}>LIVE</Radio>
              <Radio value={Mode.TEST}>TEST</Radio>
            </VStack>
          </RadioGroup>
          <Text fontSize="lg" mt={4} mb={2}>
            Select Organization
          </Text>
          <SelectRoot
            disabled={tokenIsTooLong || isPending}
            w="full"
            collection={orgCollections}
            value={selectedOrgId ? [selectedOrgId] : undefined}
            onValueChange={({ value }) => setSelectedOrgId(value[0])}
          >
            <SelectTrigger>
              <SelectValueText />
            </SelectTrigger>
            <SelectContent>
              {orgCollections.items.map(item => (
                <SelectItem key={item.external_id} item={item}>
                  {item.name}
                </SelectItem>
              ))}
            </SelectContent>
          </SelectRoot>
          {tokenIsTooLong && <Text color="red">You are member to too many organizations</Text>}
          <Button w="full" mt={4} onClick={handleInstall} disabled={!selectedMode || !selectedOrgId || tokenIsTooLong}>
            Install
          </Button>
        </Box>
      </Flex>
    </Flex>
  );
};

export default StripeAppInstallWithOrgSelector;
