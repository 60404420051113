import { DrawerBackdrop, DrawerBody, DrawerContent, DrawerRoot } from 'components/ui/drawer';
import { TransactionDetails } from 'pages/Transactions/components/transaction-details';
import { useSearchParams } from 'react-router-dom';

export const TransactionDetailsDrawer = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const transactionId = searchParams.get('transaction_id');

  if (!transactionId) {
    return null;
  }

  const closeDrawer = () => {
    searchParams.delete('transaction_id');
    setSearchParams(searchParams);
  };

  return (
    <DrawerRoot
      open={!!transactionId}
      size="xl"
      placement="end"
      onOpenChange={({ open }) => {
        if (!open) {
          closeDrawer();
        }
      }}
      onInteractOutside={closeDrawer}
    >
      <DrawerBackdrop />
      <DrawerContent>
        <DrawerBody>
          <TransactionDetails transactionId={transactionId} onClick={closeDrawer} />
        </DrawerBody>
      </DrawerContent>
    </DrawerRoot>
  );
};
