import { Box, Button, Flex, Heading, HStack, IconButton, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { CREDITS_SERVICE_QUERY_KEYS, getCredits } from 'apis/credits-service';
import { useGetRegistrationById } from 'apis/registration-apis';
import { ArrowLeft } from 'components/icons';
import { PaginationButtons } from 'components/pagination/pagination';
import { KDataTable } from 'components/table/data-table';
import { TableEmptyState } from 'components/table-empty-state/table-empty-state';
import { DrawerBackdrop, DrawerBody, DrawerContent, DrawerHeader, DrawerRoot } from 'components/ui/drawer';
import { TableRowSkeleton } from 'components/ui/table-row-skeleton';
import { Tooltip } from 'components/ui/tooltip';
import { useOrg } from 'hooks/useOrg';
import { useState } from 'react';
import { CreditsListType } from 'types/credits';
import { RegistrationsRegimeEnum } from 'types/shared-types';

import { CreateCreditsModal } from './create-credits';
import CreditTableRow from './credit-table-row';

interface CreditsDrawerProps {
  onClose: () => void;
  registrationId: string;
}

const TABLE_HEAD = ['INSTANCE', 'DATE', 'TYPE', 'EXPIRY DATE', 'CREDIT AMOUNT', 'STATUS'];
const NUMERIC_COL = ['CREDIT AMOUNT'];

export function CreditsDrawer({ onClose, registrationId }: CreditsDrawerProps) {
  const { orgId } = useOrg();
  const { data } = useGetRegistrationById({ orgId, registrationId });
  const [isCreateCreditsModalOpen, setIsCreateCreditsModalOpen] = useState(false);

  const [{ page, size }, setPagination] = useState<{ page: number; size: number }>({
    page: 1,
    size: 25,
  });

  const { data: creditsResponse, isPending: isCreditsPending } = useQuery({
    queryKey: [...CREDITS_SERVICE_QUERY_KEYS.list(orgId, registrationId)],
    queryFn: () => getCredits(orgId, registrationId, { page, size }),
  });

  const handlePagination = async (page: number, size: number) => {
    setPagination({ page, size });
  };

  const credits = creditsResponse?.items ?? [];
  const totalPages = creditsResponse?.pages ?? 0;
  const creditsTotalAvailable = data?.credits_total_available ?? 0;
  const isSimplifiedRegime = data?.registrations_regime === RegistrationsRegimeEnum.SIMPLIFIED;

  return (
    <DrawerRoot open={!!registrationId} size="xl" placement="end" onInteractOutside={onClose}>
      <DrawerBackdrop />
      <DrawerContent>
        <DrawerHeader>
          <Flex alignItems="center" justifyContent="space-between" flexWrap="wrap" width="100%">
            <HStack gap={2} alignItems={'flex-start'}>
              <IconButton
                rounded={'sm'}
                size="xs"
                variant={'ghost'}
                aria-label="back-to-filings"
                onClick={onClose}
                mt={'3px'}
              >
                <ArrowLeft w={6} h={6} />
              </IconButton>
              <Box>
                <Heading size="xl" fontWeight="medium">
                  Credits
                </Heading>
                <Text color="#3C4059">Total Available Credit: {creditsTotalAvailable}</Text>
              </Box>
            </HStack>
            {data?.state_code === 'FD' && (
              <Tooltip
                content="Credits can't be added for this jurisdiction since the registration regime is 'Simplified'."
                disabled={!isSimplifiedRegime}
              >
                <Button
                  variant="solid"
                  colorPalette={'blue'}
                  onClick={() => {
                    setIsCreateCreditsModalOpen(true);
                  }}
                  disabled={isSimplifiedRegime}
                  width={'100px'}
                >
                  New
                </Button>
              </Tooltip>
            )}
          </Flex>
        </DrawerHeader>
        <DrawerBody>
          {!isCreditsPending && credits.length === 0 ? (
            <TableEmptyState tableName="Credits" tableUniqueMessage="No Credits Exist" />
          ) : (
            <>
              <KDataTable headers={TABLE_HEAD} numericCols={NUMERIC_COL} isPending={isCreditsPending}>
                {credits?.map((row: CreditsListType) => <CreditTableRow key={row.id} credits={row} />)}
                {isCreditsPending && <TableRowSkeleton length={25} columns={TABLE_HEAD} />}
              </KDataTable>
              <Flex justifyContent="center" mt={2}>
                <PaginationButtons
                  size={size}
                  currentPage={page}
                  totalPages={totalPages}
                  onPageClick={handlePagination}
                  onSizeChange={handlePagination}
                />
              </Flex>
            </>
          )}
        </DrawerBody>
      </DrawerContent>
      {
        <CreateCreditsModal
          isOpen={isCreateCreditsModalOpen}
          onClose={() => setIsCreateCreditsModalOpen(false)}
          registration_id={registrationId}
        />
      }
    </DrawerRoot>
  );
}
