import { Flex, Text, useDisclosure } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  ORGANIZATION_SERVICE_QUERY_KEYS,
  putAutoFileAutoRegister,
  useAutoFileAutoRegisterQuery,
} from 'apis/organizations-apis';
import { usePaywall } from 'app/acl/paywall';
import { Button } from 'components/ui/button';
import { DrawerBody, DrawerContent, DrawerFooter, DrawerHeader, DrawerRoot, DrawerTitle } from 'components/ui/drawer';
import { useFormik } from 'formik';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import useQueryParam from 'hooks/useQueryParam';
import { useEffect } from 'react';
import { AutoFileAutoRegisterUpdateRequest } from 'types/shared-types';

import { AutoFileRegisterToggle } from './auto-file-register-toggle';

export const AutoFileRegisterModal = () => {
  const { orgId } = useOrg();
  const { isPaidUser } = usePaywall();
  const { open, onClose, onOpen } = useDisclosure();
  const { handleFailNotification } = useHandleNotification();
  const [showAutoFileReg, setSearchParams] = useQueryParam('showAutoFileReg');
  const queryClient = useQueryClient();
  const { isPending, data: orgDetails, isError } = useAutoFileAutoRegisterQuery(orgId);

  const mutation = useMutation({
    mutationFn: (payload: AutoFileAutoRegisterUpdateRequest) => {
      return putAutoFileAutoRegister(orgId, payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [...ORGANIZATION_SERVICE_QUERY_KEYS.autoFileAutoRegister(orgId)] });
      queryClient.invalidateQueries({ queryKey: [...ORGANIZATION_SERVICE_QUERY_KEYS.details(orgId)] });
      onClose();
    },
    onError: (error: any) => {
      handleFailNotification(error);
    },
  });

  const formik = useFormik({
    initialValues: {
      auto_file: false,
      auto_register: false,
    },
    onSubmit: values => {
      mutation.mutate(values);
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (orgDetails) {
      formik.setValues({
        auto_file: orgDetails.auto_file,
        auto_register: orgDetails.auto_register,
      });
    }
  }, [orgDetails]);

  const handleSubmit = () => {
    formik.handleSubmit();
    onClose();
  };

  useEffect(() => {
    if (showAutoFileReg && isPaidUser) {
      onOpen();
    }
  }, [showAutoFileReg]);

  const handleClose = () => {
    setSearchParams({});
    onClose();
  };

  if (isPending || isError) return null;

  return (
    <DrawerRoot open={open} size={'lg'}>
      <DrawerContent>
        <DrawerHeader>
          <DrawerTitle>Turn ON Auto Features</DrawerTitle>
        </DrawerHeader>
        <DrawerBody>
          <Text>
            Turn ON auto features so that you never miss a due date. You can toggle OFF from &apos;Settings&apos;
            anytime.
          </Text>
          <form onSubmit={formik.handleSubmit}>
            <AutoFileRegisterToggle
              label="Auto File"
              fieldName="auto_file"
              subtitle="Automatically approve all the filings on the 10th of the month. You can still approve manually before the
            filing is auto-approved."
              formik={formik}
            />
            <AutoFileRegisterToggle
              label="Auto Register"
              subtitle="Automatically approve registrations as soon as the nexus is met."
              fieldName="auto_register"
              formik={formik}
            />
          </form>
        </DrawerBody>
        <DrawerFooter>
          <Flex gap={2}>
            <Button
              variant={'outline'}
              color={'gray.800'}
              borderColor={'gray.100'}
              onClick={handleClose}
              width={'70px'}
            >
              Cancel
            </Button>
            <Button
              variant={'solid'}
              width={'90px'}
              onClick={handleSubmit}
              disabled={!formik.values.auto_file && !formik.values.auto_register}
            >
              Save
            </Button>
          </Flex>
        </DrawerFooter>
      </DrawerContent>
    </DrawerRoot>
  );
};
