import { ApideckVault, Connection } from '@apideck/vault-js';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  CONNECTIONS_STATE_KEY,
  createApiDeckConnection,
  deleteApiDeckConnection,
  getConnections,
  updateApiDeckConnections,
} from 'apis/connections';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import useTracking from 'hooks/useTracking';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ItemCard } from '../item-card';
import { AmazonSellerCentralIcon } from './icons/amazon';

export default function AmazonTab({ isConnected = false }: { isConnected?: boolean }) {
  const { orgId } = useOrg();
  const { track } = useTracking();
  const { handleFailNotification } = useHandleNotification();
  const { handleSuccessNotification } = useHandleNotification();
  const navigate = useNavigate();

  const { refetch } = useQuery({
    queryKey: [CONNECTIONS_STATE_KEY, orgId],
    queryFn: async () => {
      const { data } = await getConnections(orgId);
      return data;
    },
    enabled: false,
  });

  const [connectionData, setConnectionData] = useState({
    consumer_id: '',
  });

  const { mutateAsync: doUpdateApideckConnections, isPending } = useMutation({
    mutationFn: (status: string) => {
      return updateApiDeckConnections(connectionData.consumer_id, orgId, status);
    },
    onSuccess: () => {
      handleSuccessNotification('Amazon Connected SuccessFully.');
      navigate('/source');
    },
    onError: error => {
      handleFailNotification(error);
    },
  });

  const handleConnectionChange = async (connection: Connection) => {
    if (connection.state === 'callable') {
      await doUpdateApideckConnections('ACTIVE');
      track('connects integration', { integration: 'amazon-seller-central', status: 'success' });
    }
    if (connection.state === 'added' && connectionData.consumer_id) {
      updateApiDeckConnections(connectionData.consumer_id, orgId, 'INACTIVE');
      track('connects integration', { integration: 'amazon-seller-central', status: 'failed' });
    }
  };

  const { mutate: doCreateApideckConnection, isPending: isCreating } = useMutation({
    mutationFn: async () => {
      const { data } = await createApiDeckConnection(orgId, 'amazon-seller-central', {});
      return data;
    },
    onSuccess: data => {
      const token = data['token'];
      const connectionId = data['connection_id'];
      setConnectionData({ ...connectionData, consumer_id: connectionId });
      ApideckVault.open({
        token: token,
        unifiedApi: 'ecommerce',
        serviceId: 'amazon-seller-central',
        onConnectionChange: handleConnectionChange,
        onClose: async () => {
          const { data } = await refetch();

          const isConnectionExists = (data?.items || []).find(({ id }) => id === connectionId);

          if (isConnectionExists) {
            deleteApiDeckConnection(connectionId, orgId);
          }
          setConnectionData({ consumer_id: '' });
        },
      });
    },
    onError: error => {
      handleFailNotification(error);
    },
  });

  return (
    <ItemCard
      title="Amazon"
      description="Open-source eCommerce toolkit."
      isConnected={isConnected}
      icon={<AmazonSellerCentralIcon />}
      onConnect={doCreateApideckConnection}
      btnProps={{ loading: isCreating || isPending, colorPalette: 'lightGray' }}
    />
  );
}
