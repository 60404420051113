import { Badge, Card, Flex, Icon, IconButton, Text, VStack } from '@chakra-ui/react';
import { OpenNewWindowIcon } from 'components/icons';
import { Button, ButtonProps } from 'components/ui/button';
import { Tooltip } from 'components/ui/tooltip';
import { Link } from 'react-router-dom';

type ItemCardProps = {
  title: string;
  description: string;
  icon: React.ReactNode;
  onConnect: () => void;
  isConnected: boolean;
  btnProps?: ButtonProps;
  installationGuide?: string;
};

export const ItemCard = ({
  title,
  description,
  icon,
  isConnected = false,
  onConnect,
  btnProps = {},
  installationGuide,
}: ItemCardProps) => {
  return (
    <Card.Root
      p={4}
      gap={4}
      border={'1px #CFD0D8 solid'}
      borderRadius={'4px'}
      justifyContent={'space-between'}
      alignItems={'flex-start'}
      width={'400px'}
    >
      <Flex justify={'space-between'} width={'100%'}>
        <Icon w="50px" h="50px">
          {icon}
        </Icon>
        <Tooltip
          content="The installation guide will be available soon. Contact support for assistance."
          disabled={!!installationGuide}
          positioning={{ placement: 'bottom-start' }}
        >
          <Flex height={'22px'} align={'center'} gap={2}>
            <Text color={installationGuide ? 'gray.700' : 'gray.200'} fontSize={'12px'} cursor={'default'}>
              View Installation Guide
            </Text>
            <IconButton
              asChild
              aria-label="View Installation Guide"
              cursor={installationGuide ? 'pointer' : 'default'}
              variant={'transparent-with-icon'}
              minWidth={'12px'}
              opacity={installationGuide ? 1 : 0.5}
            >
              {installationGuide ? (
                <Link to={installationGuide} target="_blank">
                  <OpenNewWindowIcon />
                </Link>
              ) : (
                <OpenNewWindowIcon />
              )}
            </IconButton>
          </Flex>
        </Tooltip>
      </Flex>

      <VStack alignItems="flex-start" justify={'center'} gap={2}>
        <Text fontSize={'18px'} fontWeight={'500'}>
          {title}
          {isConnected && (
            <Badge colorPalette="green" fontWeight="normal" px={2.5} ml={2}>
              Connected
            </Badge>
          )}
        </Text>
        <Text fontSize={'sm'} color={'#4B5169'}>
          {description}
        </Text>
      </VStack>
      <Button colorPalette="lightGray" onClick={onConnect} width={'100%'} {...btnProps}>
        Connect
      </Button>
    </Card.Root>
  );
};
