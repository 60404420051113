import { Input, SimpleGrid, Text, Textarea } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CREDITS_SERVICE_QUERY_KEYS, doCreateCredits } from 'apis/credits-service';
import { REGISTRATION_STATE_KEY } from 'apis/registration-apis';
import { AxiosError } from 'axios';
import { KCustomSelect } from 'components/k-custom-select/k-custom-select';
import { Button } from 'components/ui/button';
import DatePicker from 'components/ui/date-picker';
import {
  DialogActionTrigger,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from 'components/ui/dialog';
import { Field } from 'components/ui/field';
import { InputGroup } from 'components/ui/input-group';
import { useFormik } from 'formik';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import { CreditsPayload, CreditsType, CreditsValidationSchema, CreditsValidationSchemaType } from 'types/credits';
import { CountryCodeEnum } from 'types/shared-types';
import { getFormattedDate } from 'utils/dates';

interface CreateCreditModalProps {
  isOpen: boolean;
  onClose: () => void;
  registration_id: string;
}

export const CreateCreditsModal = ({ isOpen, onClose, registration_id }: CreateCreditModalProps) => {
  const { orgId } = useOrg();
  const { handleSuccessNotification, handleFailNotification } = useHandleNotification();
  const queryClient = useQueryClient();

  const { mutate: createCredits, isPending: isCreatingCredits } = useMutation({
    mutationFn: (payload: CreditsPayload) => doCreateCredits(orgId, payload),
    onSuccess: () => {
      handleSuccessNotification('Credit has been successfully added.');
      queryClient.invalidateQueries({ queryKey: [...CREDITS_SERVICE_QUERY_KEYS.list(orgId, registration_id)] });
      queryClient.invalidateQueries({ queryKey: [REGISTRATION_STATE_KEY, registration_id] });
      onCloseModal();
    },
    onError: (error: AxiosError) => {
      handleFailNotification(error);
    },
  });

  const { errors, touched, resetForm, values, isValid, dirty, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      registration_id,
      country_code: CountryCodeEnum.CA, // Always Canada
      state_code: 'FD', // Always Canada (Federal)
      type: CreditsType.ITC,
      amount: '',
      start_date: getFormattedDate(new Date(), 'yyyy-MM-dd'),
      end_date: null,
      comment: '',
    },
    validationSchema: CreditsValidationSchema,
    onSubmit: async (submitValues: CreditsValidationSchemaType) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { country_code, state_code, ...payloadData } = submitValues;
      await createCredits({ ...payloadData, end_date: payloadData.end_date ?? null });
    },
  });

  const onCloseModal = () => {
    resetForm({});
    onClose();
  };

  return (
    <DialogRoot open={isOpen} placement={'center'}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>New Credits</DialogTitle>
        </DialogHeader>

        <DialogBody pt={0}>
          <Text fontSize="sm" color="gray.900">
            Add tax credits for this jurisdiction. These will be utilized in the upcoming filings.
          </Text>
          <SimpleGrid columns={2} gap={4} mt={4}>
            <Field label="Country">
              <KCustomSelect
                id="country_code"
                name="country_code"
                disabled={true}
                items={[{ label: 'Canada', value: CountryCodeEnum.CA }]}
                value={values.country_code ? ([values.country_code] as string[]) : undefined}
                onValueChange={async ({ value }) => {
                  setFieldValue('country_code', value[0]);
                  setFieldValue('state', []);
                }}
              />
            </Field>
            <Field
              label="Jurisdiction"
              invalid={!!(errors.state_code && touched.state_code)}
              errorText={String(errors.state_code)}
            >
              <KCustomSelect
                id="state_code"
                name="state_code"
                disabled={true}
                items={[{ label: 'Canada (Federal)', value: 'FD' }]}
                value={values.state_code ? ([values.state_code] as string[]) : undefined}
                onValueChange={({ value }) => {
                  setFieldValue('state_code', value[0]);
                }}
              />
            </Field>
          </SimpleGrid>
          <SimpleGrid columns={2} gap={4} mt={4}>
            <Field label="Credit Amount" required>
              <InputGroup startElement={<Text color={'gray.700'}>CAD</Text>} width={'100%'}>
                <Input
                  ps="3rem"
                  id="amount"
                  name="amount"
                  value={values.amount}
                  onChange={e => setFieldValue('amount', Number(e.target.value))}
                  placeholder="Enter amount"
                />
              </InputGroup>
            </Field>
            <Field
              label="Expiry Date"
              invalid={!!(errors.end_date && touched.end_date)}
              errorText={String(errors.end_date)}
            >
              <DatePicker
                placeholderText="Select"
                id="end_date"
                name="end_date"
                selected={values.end_date}
                onChange={date => {
                  setFieldValue('end_date', date);
                }}
              />
            </Field>
          </SimpleGrid>
          <SimpleGrid columns={1} mt={4}>
            <Field label="Comment">
              <Textarea value={values.comment} onChange={e => setFieldValue('comment', e.target.value)} />
            </Field>
          </SimpleGrid>
        </DialogBody>

        <DialogFooter>
          <DialogActionTrigger asChild>
            <Button variant="outline" onClick={onCloseModal}>
              Cancel
            </Button>
          </DialogActionTrigger>
          <Button
            onClick={() => handleSubmit()}
            variant={'solid'}
            colorPalette={'blue'}
            width={'90px'}
            loading={isCreatingCredits}
            disabled={!isValid || !dirty}
          >
            Save
          </Button>
        </DialogFooter>

        <DialogCloseTrigger onClick={onCloseModal} />
      </DialogContent>
    </DialogRoot>
  );
};
