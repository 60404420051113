import { defineRecipe } from '@chakra-ui/react';

export const buttonRecipe = defineRecipe({
  base: {
    borderRadius: '2px',
    fontWeight: 'medium',
  },
  variants: {
    variant: {
      nav: {
        fontWeight: 'normal',
        width: '188px',
        height: '44px',
        justifyContent: 'flex-start',
        borderRadius: '8px',
        px: '3.5',
        bg: 'transparent',
        color: '#FFFFFF',
        _hover: {
          bg: '#3A3F5B',
        },
        _active: {
          bg: '#3A3F5B',
          '&::before': {
            content: '""',
            position: 'absolute',
            left: 0,
            top: '10px',
            bottom: 0,
            borderRadius: '4px',
            width: '4px',
            height: '24px',
            background: '#FFB000',
          },
        },
      },
      'secondary-outline': {
        border: '2px',
        borderRadius: '4px',
        borderColor: '#CFD0D8',
        bgColor: 'white',
        textColor: '#383D58',
        height: '32px',
        _hover: {
          borderColor: '#B7BAC5',
          bgColor: '#EFEFF3',
          textColor: '#383D58',
        },
        _active: {
          bgColor: '#CFD0D8',
          borderColor: '#A0A4B2',
          textColor: '#383D58',
        },
        _disabled: {
          borderColor: '#B7BAC5',
          textColor: '#B7BAC5',
          bgColor: '#383D58',
        },
      },
      disabled: {
        border: '2px',
        borderRadius: '4px',
        borderColor: '#B7BAC5',
        textColor: '#B7BAC5',
        bgColor: 'white',
      },
      paging: {
        width: '36px',
        height: '36px',
        mx: '4px',
        border: '1px',
        borderRadius: '2px',
        borderColor: '#CFD0D8',
        bgColor: 'white',
        textColor: '#383D58',
        _hover: {
          borderColor: '#CFD0D8',
          bgColor: '#EFEFF3',
          textColor: '#383D58',
        },
        fontWeight: 'normal',
      },
      'transparent-with-icon': {
        bg: 'transparent',
        fontWeight: 'bold',
        borderRadius: 'inherit',
        cursor: 'pointer',
        _active: {
          bg: 'transparent',
          transform: 'none',
          borderColor: 'transparent',
        },
        _focus: {
          boxShadow: 'none',
        },
        _hover: {
          boxShadow: 'none',
        },
      },
      danger: {
        bg: 'red.500',
        textColor: '#FFFFFF',
        w: '5.625rem',
        h: '2rem',
        _hover: {
          bg: 'red.500',
          _loading: {
            bg: 'red.500',
            opacity: 0.8,
          },
          _disabled: {
            bg: 'red.500',
            opacity: 0.8,
          },
        },
        _active: {
          bg: 'red.500',
        },
      },
      outline: {
        fontWeight: 'medium',
        _hover: {
          bg: 'colorPalette.50',
          borderColor: 'colorPalette.600',
          color: 'colorPalette.600',
        },
        _active: {
          bg: 'colorPalette.100',
          borderColor: 'colorPalette.700',
          color: 'colorPalette.700',
        },
        _disabled: {
          borderColor: 'gray.100',
          color: 'gray.200',
        },
      },
      solid: {
        fontWeight: '500',
        _hover: {
          bg: 'colorPalette.600',
        },
        _active: {
          bg: 'colorPalette.700',
        },
        _disabled: {
          bg: 'colorPalette.50',
          color: 'gray.300',
        },
      },
    },
    size: {
      lg: {
        h: 12,
        textStyle: 'lg',
        gap: 2,
        px: 4,
        _icon: {
          width: '6',
          height: '6',
        },
      },
      md: {
        h: 10,
        gap: 2,
        px: 4,
        _icon: {
          width: '5',
          height: '5',
        },
      },
      sm: {
        h: 8,
        gap: 2,
        px: 2,
        _icon: {
          width: '5',
          height: '5',
        },
      },
      xs: {
        h: 6,
        gap: 2,
        px: 2,
        _icon: {
          width: '5',
          height: '5',
        },
      },
    },
  },
  compoundVariants: [
    {
      size: 'lg',
      variant: 'outline',
      css: {
        borderWidth: '2px',
      },
    },
    {
      size: 'md',
      variant: 'outline',
      css: {
        borderWidth: '2px',
      },
    },
  ],
  defaultVariants: {
    size: 'sm',
  },
});
