import { Text } from '@chakra-ui/react';
import { useGetRegistrationById } from 'apis/registration-apis';
import { Button } from 'components/ui/button';
import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from 'components/ui/dialog';
import { useOrg } from 'hooks/useOrg';
import { ChangeRegimeStatusEnum } from 'types/shared-types';

import { UpdateRegistrationApiParams } from './table';

interface AcknowledgeRegimeChangeModalProps {
  registrationId: string;
  onClose: () => void;
  handleRegimeChange: (val: UpdateRegistrationApiParams) => void;
  isLoading: boolean;
}

const AcknowledgeRegimeChangeModal = ({
  registrationId,
  onClose,
  handleRegimeChange,
  isLoading,
}: AcknowledgeRegimeChangeModalProps) => {
  const { orgId } = useOrg();
  const { data: registrationData } = useGetRegistrationById({ orgId, registrationId });
  if (!registrationData) {
    return null;
  }
  return (
    <DialogRoot
      open={!!registrationId}
      onOpenChange={({ open }) => {
        if (!open) {
          onClose();
        }
      }}
      placement="center"
      size={'md'}
      restoreFocus={false}
    >
      <DialogBackdrop />
      <DialogContent maxW={'500px'}>
        <DialogHeader fontSize={'lg'} fontWeight={'medium'}>
          <DialogTitle>Acknowledge Regime Change</DialogTitle>
        </DialogHeader>
        <DialogCloseTrigger />
        <DialogBody pt={0}>
          <Text>
            Unlike the &apos;Simplified&apos; regime, the &apos;General&apos; regime requires you to collect and remit
            sales tax on B2B transactions as well. We&apos;ll do this for you if you use Kintsugi for tax collections.
            Otherwise, please ensure that you collect tax on B2B transactions, just like B2C transactions.
          </Text>
        </DialogBody>
        <DialogFooter gap={3}>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant={'solid'}
            width={'90px'}
            onClick={() =>
              handleRegimeChange({
                registrationId,
                status: registrationData.status,
                registration_email: registrationData.registration_email,
                change_regime_status: ChangeRegimeStatusEnum.ACKNOWLEDGED,
              })
            }
            colorPalette={'blue'}
            loading={isLoading}
          >
            Confirm
          </Button>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};

export default AcknowledgeRegimeChangeModal;
