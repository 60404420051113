import { useMutation, useQueryClient } from '@tanstack/react-query';
import { REGISTRATION_STATE_KEY, updateRegistrationApi } from 'apis/registration-apis';
import AlertBanner from 'components/alert/alert';
import { KDataTable } from 'components/table/data-table';
import { TableEmptyState } from 'components/table-empty-state/table-empty-state';
import { TableRowSkeleton } from 'components/ui/table-row-skeleton';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { RegistrationInstance } from 'types/shared-types';

import { CreditsDrawer } from './credits/credits-drawer';
import { DeRegistration } from './de-registration';
import { EditRegistrationForm } from './edit-imported-registration';
import AcknowledgeRegimeChangeModal from './regime-change-modal';
import { RegistrationDetailsDrawer } from './registration-details';
import RegistrationTableRow from './registration-table-row';

type RegistrationsTableProps = {
  data: RegistrationInstance[];
  hasAwaitingClarification: boolean;
  isPending: boolean;
};

const TABLE_HEAD = [
  'Country',
  'Jurisdiction',
  'Registration Date',
  'Registration Email',
  'Filing Frequency',
  'Registration Fees',
  'Status',
];

const DEFAULT_VISIBLE_COLUMNS = TABLE_HEAD;

const NUMERIC_COL = ['Registration Fees'];

const COLUMN_WIDTHS = {
  Country: '150px',
  Jurisdiction: '100px',
  'Registration Date': '110px',
  'Registration Email': '130px',
  'Filing Frequency': '100px',
  'Registration Fees': '150px',
  Status: '150px',
};

export type UpdateRegistrationApiParams = {
  registrationId: string;
  status: RegistrationInstance['status'];
  registration_email: RegistrationInstance['registration_email'];
  change_regime_status: RegistrationInstance['change_regime_status'];
};

export const RegistrationsTable = ({ data, hasAwaitingClarification, isPending }: RegistrationsTableProps) => {
  const { orgId } = useOrg();
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const { handleFailNotification, handleSuccessNotification } = useHandleNotification();
  const deRegisterId = searchParams.get('deRegisterId');
  const editImportedRegistrationId = searchParams.get('editImportedRegistrationId');
  const creditsId = searchParams.get('creditsId');
  const registrationDetailsId = searchParams.get('registrationDetailsId');
  const acknowledgeRegimeId = searchParams.get('acknowledgeRegimeId');

  const isDataEmpty = !data || data.length === 0;

  const [visibleColumns, setVisibleColumns] = useState<string[]>(DEFAULT_VISIBLE_COLUMNS);

  const handleVisibleColumnsChange = (columns: string[]) => {
    setVisibleColumns(columns);
  };

  const { mutate: handleRegimeChange, isPending: isRegimeChangePending } = useMutation({
    mutationFn: ({ registrationId, status, registration_email, change_regime_status }: UpdateRegistrationApiParams) => {
      if (!registrationId || !orgId) {
        console.error('registrationId or orgId is undefined');
        return Promise.reject(new Error('id or orgId is undefined'));
      }
      return updateRegistrationApi(registrationId, orgId, {
        status,
        registration_email,
        change_regime_status,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [REGISTRATION_STATE_KEY] });
      if (acknowledgeRegimeId) {
        handleSuccessNotification('Acknowledgement successfully confirmed.');
        setSearchParams(params => {
          params.delete('acknowledgeRegimeId');
          return params;
        });
      } else {
        handleSuccessNotification('Request successfully submitted.');
      }
    },
    onError: (err: any) => {
      handleFailNotification(err);
    },
  });

  if (isDataEmpty && !isPending) {
    return (
      <TableEmptyState
        tableName="Registrations"
        tableUniqueMessage="No Registrations Exist"
        uniqueHelperTxt="Please register in a jurisdiction or import existing registrations to generate filings."
      />
    );
  }

  return (
    <>
      {hasAwaitingClarification && (
        <AlertBanner
          message=" We await your input before proceeding with your highlighted requests on this page. Please respond to
            support."
          width={{ xl: '75%' }}
        />
      )}

      <KDataTable
        headers={TABLE_HEAD}
        numericCols={NUMERIC_COL}
        defaultVisibleColumns={visibleColumns}
        onVisibleColumnsChange={handleVisibleColumnsChange}
        showColumnFilter
        columnWidths={COLUMN_WIDTHS}
        isPending={isPending}
      >
        {data.map((row: RegistrationInstance) => (
          <RegistrationTableRow
            key={row.id}
            data={row}
            handleRegimeChange={handleRegimeChange}
            visibleColumns={visibleColumns}
          />
        ))}
        {isPending && <TableRowSkeleton length={25} columns={visibleColumns} />}
      </KDataTable>

      {!!deRegisterId && (
        <DeRegistration
          registrationId={deRegisterId}
          onClose={() => {
            setSearchParams(params => {
              params.delete('deRegisterId');
              return params;
            });
          }}
        />
      )}
      {!!editImportedRegistrationId && (
        <EditRegistrationForm
          registrationId={editImportedRegistrationId}
          onClose={() => {
            setSearchParams(params => {
              params.delete('editImportedRegistrationId');
              return params;
            });
          }}
        />
      )}
      {!!creditsId && (
        <CreditsDrawer
          registrationId={creditsId}
          onClose={() => {
            setSearchParams(params => {
              params.delete('creditsId');
              return params;
            });
          }}
        />
      )}
      {!!registrationDetailsId && (
        <RegistrationDetailsDrawer
          registrationId={registrationDetailsId}
          onClose={() =>
            setSearchParams(params => {
              params.delete('registrationDetailsId');
              return params;
            })
          }
        />
      )}
      {!!acknowledgeRegimeId && (
        <AcknowledgeRegimeChangeModal
          registrationId={acknowledgeRegimeId}
          onClose={() =>
            setSearchParams(params => {
              params.delete('acknowledgeRegimeId');
              return params;
            })
          }
          handleRegimeChange={handleRegimeChange}
          isLoading={isPending || isRegimeChangePending}
        />
      )}
    </>
  );
};
