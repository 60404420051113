import { Box, Flex, Table, Text } from '@chakra-ui/react';
import { sendRegistrationPassword } from 'apis/registration-apis';
import { usePaywall } from 'app/acl/paywall';
import { KebabIcon, WarningIcon } from 'components/icons';
import { MenuContent, MenuItem, MenuRoot, MenuTrigger } from 'components/ui/menu';
import { toaster } from 'components/ui/toaster';
import { Tooltip } from 'components/ui/tooltip';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import { useSearchParams } from 'react-router-dom';
import { ChangeRegimeStatusEnum, CountryCodeEnum, RegistrationInstance, RegistrationStatus } from 'types/shared-types';
import { getCountryDisplay } from 'utils';
import { getFilingFreq } from 'utils/enum-helpers';
import { formatCurrency } from 'utils/utils';

import RegistrationStateBadge from './registration-state-badge';
import { UpdateRegistrationApiParams } from './table';
import { VDAProcessNeeded } from './vda-process-needed';

type RegistrationTableRowProps = {
  data: RegistrationInstance;
  visibleColumns?: string[];
  handleRegimeChange: (val: UpdateRegistrationApiParams) => void;
};

const RegistrationTableRow = ({ data, visibleColumns, handleRegimeChange }: RegistrationTableRowProps) => {
  const { orgId } = useOrg();
  const {
    id,
    country_code,
    state_code,
    registration_date,
    registration_email,
    filing_frequency,
    status,
    has_all_credentials,
    amount_fees,
    vda,
    change_regime_status,
  } = data || {};
  const [, setSearchParams] = useSearchParams();
  const { isSubscriptionPausedOrCanceled } = usePaywall();

  const { handleFailNotification, handleSuccessNotification } = useHandleNotification();

  const isRegistered = status === RegistrationStatus.REGISTERED;
  const isImporting = status === RegistrationStatus.VALIDATING || status === RegistrationStatus.AWAITING_CLARIFICATION;
  const isCreditOptionAvailable = country_code === CountryCodeEnum.CA;
  const isChangeRegimeOptionAvailable =
    change_regime_status === ChangeRegimeStatusEnum.REQUESTED && country_code === CountryCodeEnum.CA;
  const isAcknowledgeRegimeOptionAvailable =
    change_regime_status === ChangeRegimeStatusEnum.DONE && country_code === CountryCodeEnum.CA;

  const handleSendPassword = async (registration_id: string) => {
    try {
      const response = await sendRegistrationPassword(orgId, registration_id);
      if (response.status === 200) {
        handleSuccessNotification('Check your email to get the credentials for this jurisdiction.');
      } else {
        toaster.create({
          title: 'Username and/or password not found',
          type: 'error',
        });
      }
    } catch (error) {
      console.error('Error while getting password:', error);
      handleFailNotification(error);
    }
  };

  const getChangeRegimeStatusTooltipContent = (change_regime_status: ChangeRegimeStatusEnum) => {
    if (change_regime_status === ChangeRegimeStatusEnum.REQUESTED) {
      return 'To remain compliant in this jurisdiction, please submit the request to change the registration regime.';
    }
    return "We have successfully changed the registration regime. To remain compliant, please acknowledge that you've started collecting tax on B2B transactions.";
  };

  const editImportedMenuContent = (
    <MenuRoot
      onSelect={({ value }) => {
        if (value === 'editImportedRegistration') {
          setSearchParams(params => {
            params.set('editImportedRegistrationId', id);
            return params;
          });
        }
      }}
    >
      <MenuTrigger onClick={e => e.stopPropagation()}>
        <KebabIcon />
      </MenuTrigger>
      <MenuContent onClick={e => e.stopPropagation()}>
        <MenuItem value="editImportedRegistration">Edit</MenuItem>
      </MenuContent>
    </MenuRoot>
  );

  const registerMenuContent = (
    <MenuRoot
      onSelect={({ value }) => {
        if (value === 'getPassword') {
          handleSendPassword(id);
        } else if (value === 'deRegister') {
          setSearchParams(params => {
            params.set('deRegisterId', id);
            return params;
          });
        } else if (value === 'credits') {
          setSearchParams(params => {
            params.set('creditsId', id);
            return params;
          });
        } else if (value === 'changeRegime') {
          handleRegimeChange({
            registrationId: id,
            status,
            registration_email,
            change_regime_status: ChangeRegimeStatusEnum.APPROVED,
          });
        } else if (value === 'acknowledgeRegime') {
          setSearchParams(params => {
            params.set('acknowledgeRegimeId', id);
            return params;
          });
        }
      }}
    >
      <MenuTrigger onClick={e => e.stopPropagation()}>
        <KebabIcon />
      </MenuTrigger>
      <MenuContent onClick={e => e.stopPropagation()}>
        {isChangeRegimeOptionAvailable && (
          <MenuItem value="changeRegime">
            <Text>Change Regime</Text>
          </MenuItem>
        )}
        {isAcknowledgeRegimeOptionAvailable && (
          <MenuItem value="acknowledgeRegime">
            <Text>Mark as Acknowledged</Text>
          </MenuItem>
        )}
        {isCreditOptionAvailable && (
          <MenuItem value="credits">
            <Text>Credits</Text>
          </MenuItem>
        )}

        <MenuItem value="getPassword" disabled={!has_all_credentials}>
          <Tooltip
            portalled
            content={'Credentials unavailable; please contact support.'}
            disabled={has_all_credentials}
          >
            <Text>Get Credentials</Text>
          </Tooltip>
        </MenuItem>

        <MenuItem disabled={isSubscriptionPausedOrCanceled} value="deRegister">
          <Tooltip
            portalled
            content={'You need to activate subscription for de-register'}
            disabled={!isSubscriptionPausedOrCanceled}
          >
            <Text>De-register</Text>
          </Tooltip>
        </MenuItem>
      </MenuContent>
    </MenuRoot>
  );

  return (
    <Table.Row
      onClick={() =>
        setSearchParams(params => {
          params.set('registrationDetailsId', id);
          return params;
        })
      }
      _hover={{ bg: 'gray.50', cursor: 'pointer' }}
      className="group"
    >
      {visibleColumns?.includes('Country') && (
        <Table.Cell>
          <Text lineClamp={1}>{getCountryDisplay(country_code)}</Text>
        </Table.Cell>
      )}
      {visibleColumns?.includes('Jurisdiction') && (
        <Table.Cell>
          <Flex gap={1} alignItems="center">
            <Text lineClamp={1}>{state_code}</Text>
            {status === RegistrationStatus.AWAITING_CLARIFICATION && (
              <Tooltip
                content={
                  'We await your input before proceeding with your highlighted requests on this page. Please respond to support.'
                }
              >
                <WarningIcon />
              </Tooltip>
            )}
            {(isChangeRegimeOptionAvailable || isAcknowledgeRegimeOptionAvailable) && (
              <Tooltip content={getChangeRegimeStatusTooltipContent(change_regime_status)}>
                <WarningIcon width={4} height={4} />
              </Tooltip>
            )}
          </Flex>
        </Table.Cell>
      )}
      {visibleColumns?.includes('Registration Date') && (
        <Table.Cell>
          <Text lineClamp={1}>{registration_date}</Text>
        </Table.Cell>
      )}
      {visibleColumns?.includes('Registration Email') && (
        <Table.Cell>
          <Text>{registration_email}</Text>
        </Table.Cell>
      )}
      {visibleColumns?.includes('Filing Frequency') && (
        <Table.Cell>
          <Text lineClamp={1}>{getFilingFreq(filing_frequency)}</Text>
        </Table.Cell>
      )}
      {visibleColumns?.includes('Registration Fees') && (
        <Table.Cell textAlign="end">
          <Text>{formatCurrency(amount_fees)}</Text>
        </Table.Cell>
      )}
      {visibleColumns?.includes('Status') && (
        <Table.Cell>
          <Flex align={'center'} gap={2}>
            <Tooltip
              disabled={status !== RegistrationStatus.VALIDATING}
              content="We'll update the status to 'Registered' once we've validated your submitted details. We may contact you if required"
            >
              <Box>
                <RegistrationStateBadge status={status} />
              </Box>
            </Tooltip>
            <VDAProcessNeeded vda={vda} isRegistered={status === RegistrationStatus.REGISTERED} />
          </Flex>
        </Table.Cell>
      )}
      <Table.Cell>
        <Flex align={'center'} flexWrap="nowrap">
          {status !== RegistrationStatus.PROCESSING && (
            <>
              {isRegistered && registerMenuContent}
              {!isRegistered && isImporting && editImportedMenuContent}
            </>
          )}
        </Flex>
      </Table.Cell>
    </Table.Row>
  );
};

export default RegistrationTableRow;
