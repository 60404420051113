import { useDisclosure } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { getDashboardOnboardingStepStatus, ONBOARDING_STATE_KEY } from 'apis/dashboard-apis';
import { usePaywall } from 'app/acl/paywall';
import { Button } from 'components/ui/button';
import { useOrg } from 'hooks/useOrg';
import useQueryParam from 'hooks/useQueryParam';
import { OnBoardingStepStatus } from 'types/onboarding';

import { OrgDetailPrompt } from './orgdetail-prompt';

export const CreateRegistration = () => {
  const { open, onOpen, onClose } = useDisclosure();
  const { orgId, isTest } = useOrg();
  const { isPaidUser } = usePaywall();
  const [, setSearchParams] = useQueryParam('');

  const { data, isPending } = useQuery({
    queryKey: [ONBOARDING_STATE_KEY, 'steps', 'status', orgId],
    queryFn: async () => {
      const response = await getDashboardOnboardingStepStatus(orgId);
      return response.data as OnBoardingStepStatus;
    },
    enabled: !!orgId,
  });

  const handleClick = () => {
    if (isPaidUser) {
      if ((data?.bank_details_status && data?.organization_details_status && data?.physical_nexus_status) || isTest) {
        setSearchParams({ importRegistration: 'true' });
      } else {
        onOpen();
      }
    } else {
      setSearchParams({ openPricingModal: 'true' });
    }
  };

  return (
    <>
      <Button loading={isPending} variant={'solid'} colorPalette={'blue'} width={'100px'} onClick={handleClick}>
        Import
      </Button>
      {!isPending && <OrgDetailPrompt isOpen={open} onClose={onClose} data={data} />}
    </>
  );
};
