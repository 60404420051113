import { Flex, Link, Text } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deRegisterApi, REGISTRATION_STATE_KEY } from 'apis/registration-apis';
import { Button } from 'components/ui/button';
import {
  DialogBackdrop,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from 'components/ui/dialog';
import { useOrg } from 'hooks/useOrg';

const DeRegistration = ({ registrationId, onClose }: { registrationId: string; onClose: () => void }) => {
  const { orgId } = useOrg();

  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: () => {
      const response = deRegisterApi(orgId, registrationId);
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [REGISTRATION_STATE_KEY] });
      onClose();
    },
  });

  const handleSubmit = async () => {
    mutation.mutate();
  };

  return (
    <DialogRoot
      open={Boolean(registrationId)}
      onOpenChange={({ open }) => {
        if (!open) {
          onClose();
        }
      }}
    >
      <DialogBackdrop />

      <DialogContent>
        <DialogHeader>
          <DialogTitle>Are you sure you want to Deregister?</DialogTitle>
        </DialogHeader>
        <DialogBody>
          <Text>
            Once you confirm we will go through the process of de-registering you, this should be done if you are sure
            you don&#39;t meet Nexus in a state anymore. <br />
          </Text>
          <br />
          <Text>
            Not sure if this is the right option? Please{' '}
            <Link color="#4285F4" href="mailto:engineering@trykintsugi.com">
              contact us
            </Link>{' '}
            and we will guide you through it.
          </Text>
        </DialogBody>
        <DialogFooter>
          <Flex gap={2}>
            <Button variant={'outline'} onClick={onClose}>
              Cancel
            </Button>
            <Button
              loading={mutation.isPending}
              variant={'solid'}
              colorPalette={'red'}
              width={'90px'}
              onClick={handleSubmit}
            >
              Confirm
            </Button>
          </Flex>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};

export { DeRegistration };
