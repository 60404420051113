import { Badge, Flex, Table, Text } from '@chakra-ui/react';
import { CreditsListType, CreditsType } from 'types/credits';
import { toDateShort } from 'utils/dates';
import { formatCurrency } from 'utils/utils';

const CreditTableRow = ({ credits }: { credits: CreditsListType }) => {
  const { type, start_date, end_date, amount } = credits;
  const typesMap = {
    [CreditsType.ITC]: 'Input Tax Credit',
    [CreditsType.REFUND]: 'Refund',
    [CreditsType.OVERPAYMENT]: 'Overpayment',
  };

  return (
    <Table.Row>
      <Table.Cell width={{ sm: '150px' }}>
        <Text fontWeight={400}>Addition</Text>
      </Table.Cell>
      <Table.Cell width={{ sm: '150px' }}>
        <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
          <Text>{toDateShort(start_date)}</Text>
        </Flex>
      </Table.Cell>
      <Table.Cell width={{ sm: '150px' }}>
        <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
          <Text>{typesMap[type]}</Text>
        </Flex>
      </Table.Cell>
      <Table.Cell width={{ sm: '150px' }}>
        <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
          <Text>{toDateShort(end_date)}</Text>
        </Flex>
      </Table.Cell>

      {/* api not returning filling_applied */}
      {/* <Table.Cell width={{ sm: '150px' }}>
        <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
          <Text>{toDateShort(filling_applied)}</Text>
        </Flex>
      </Table.Cell> */}
      <Table.Cell width={{ sm: '100px' }} textAlign="end">
        <Text>{formatCurrency(amount)}</Text>
      </Table.Cell>
      <Table.Cell width={{ sm: '100px' }} textAlign="end">
        {end_date && new Date(end_date) > new Date() ? (
          <Badge colorPalette={'green'}>Active</Badge>
        ) : (
          <Badge colorPalette={'gray'}>Inactive</Badge>
        )}
      </Table.Cell>
    </Table.Row>
  );
};

export default CreditTableRow;
