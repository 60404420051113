import { Stack, useDisclosure } from '@chakra-ui/react';
import { AddressCard } from 'components/Address/AddressCard';
import { useState } from 'react';
import { AddressInstance } from 'types/shared-types';

import { EditTransactionAddressForm } from './edit-transaction-address-modal';

export const AddressesCards = ({
  billing,
  shipping,
}: {
  billing: AddressInstance | null;
  shipping: AddressInstance | null;
}) => {
  const { open, onOpen, onClose } = useDisclosure();
  const [payload, setPayload] = useState({});

  const openEditForm = (payload: AddressInstance) => {
    setPayload(payload);
    onOpen();
  };

  return (
    <Stack flexDirection={{ base: 'column', md: 'row' }} gap={6}>
      <EditTransactionAddressForm isOpen={open} onClose={onClose} payload={payload} />
      {billing && (
        <AddressCard
          size="lg"
          title="Bill To"
          address={billing}
          addressStatus={billing.status}
          onClickEdit={() => openEditForm(billing)}
        />
      )}
      {shipping && (
        <AddressCard
          size="lg"
          title="Ship To"
          address={shipping}
          addressStatus={shipping.status}
          onClickEdit={() => openEditForm(shipping)}
        />
      )}
    </Stack>
  );
};
