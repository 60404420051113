import {
  Box,
  createListCollection,
  Flex,
  HStack,
  IconButton,
  Input,
  SimpleGrid,
  Skeleton,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getNexus, NEXUS_STATE_KEY } from 'apis/dashboard-apis';
import { getNexusById, registerNexus } from 'apis/nexus';
import { ORGANIZATION_SERVICE_QUERY_KEYS, useOrgDetailsQuery } from 'apis/organizations-apis';
import { REGISTRATION_STATE_KEY } from 'apis/registration-apis';
import { useACL } from 'app/acl/acl';
import { usePaywall } from 'app/acl/paywall';
import { Button } from 'components/ui/button';
import { Checkbox } from 'components/ui/checkbox';
import { DialogBody, DialogContent, DialogFooter, DialogHeader, DialogRoot, DialogTitle } from 'components/ui/dialog';
import { Field } from 'components/ui/field';
import { MenuContent, MenuItem, MenuRoot, MenuTrigger } from 'components/ui/menu';
import { SelectContent, SelectItem, SelectRoot, SelectTrigger, SelectValueText } from 'components/ui/select';
import { Tooltip } from 'components/ui/tooltip';
import { useFormik } from 'formik';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import { AutoFileRegisterToggle } from 'pages/Configuration/components/org-settings/auto-file-register-toggle';
import { VdaSuggestionModal } from 'pages/Nexus/components/vda-suggestion-modal';
import { useEffect, useMemo, useRef, useState } from 'react';
import { MdMoreVert } from 'react-icons/md';
import { useSearchParams } from 'react-router-dom';
import {
  CountryCodeEnum,
  NexusInstance,
  NexusStatus,
  RegistrationInstance,
  RegistrationsRegimeEnum,
  RegistrationStatus,
} from 'types/shared-types';
import { getCountryNameByCode } from 'utils';
import { getHumanReadableString } from 'utils/enum-helpers';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  country_code: Yup.string().required('Country is required'),
  comment: Yup.string().max(255, 'Comment must be less than 255 characters'),
  state_code: Yup.string().required('State is required'),
  registration_email: Yup.string().email('Invalid email address').required('Email is required'),
  is_approaching: Yup.boolean(),
});

export const RequestRegistrationForm = () => {
  const contentRef = useRef<HTMLDivElement>(null);
  const { orgId, isTest } = useOrg();
  const { isAtLeastRole } = useACL();
  const isOwner = isAtLeastRole('Owner');
  const { isPaidUser, openPaymentCheckout } = usePaywall();
  const queryClient = useQueryClient();
  const { handleFailNotification, handleSuccessNotification } = useHandleNotification();
  const [searchParams, setSearchParams] = useSearchParams();
  const [edit, setEdit] = useState(false);
  const [vdaRequested, setVdaRequested] = useState(false);

  const requestRegistration = searchParams.get('requestRegistration') === 'true';
  const requestRegSource = searchParams.get('requestRegSource');
  const vda = searchParams.get('vda');
  const nexusId = searchParams.get('nexusId');

  const onClose = () => {
    setSearchParams({});
    setEdit(false);
  };

  const { isLoading, data, isError } = useQuery({
    queryKey: [NEXUS_STATE_KEY, nexusId],
    queryFn: async () => {
      const { data } = await getNexusById(nexusId!, orgId);
      if (data) {
        formik.setFieldValue('state_code', data.state_code);
      }
      return data;
    },
    enabled: !!nexusId,
  });

  const { data: orgDetails, isLoading: isOrgDetailsLoading } = useOrgDetailsQuery(orgId, {
    enabled: !isTest && isOwner,
  });

  const {
    data: { jurisdictions, disabledItems, nexusByStateCode, countries } = {
      jurisdictions: [],
      disabledItems: [],
      nexusByStateCode: {},
      countries: [],
    },
    isLoading: jurisdictionsLoading,
  } = useQuery<
    NexusInstance[],
    unknown,
    {
      jurisdictions: { label: string; value: string }[];
      disabledItems: string[];
      nexusByStateCode: Record<string, NexusInstance>;
      countries: { label: string; value: string }[];
    }
  >({
    queryKey: [NEXUS_STATE_KEY, orgId],
    queryFn: async () => {
      const response = await getNexus({
        orgId,
        params: {
          page: 1,
          size: 100,
        },
      });
      return response.data.items ?? [];
    },
    select: data => {
      return data.reduce<{
        jurisdictions: { label: string; value: string }[];
        disabledItems: string[];
        nexusByStateCode: Record<string, NexusInstance>;
        countries: { label: string; value: string }[];
      }>(
        (acc, curr) => {
          const { nexus_met, status, state_code, state_name, country_code } = curr;
          const isDisabled = nexus_met || status !== NexusStatus.APPROACHING;
          const countryExists = acc.countries.some(country => country.value === country_code);
          return {
            jurisdictions: [...acc.jurisdictions, { label: state_name, value: state_code }],
            disabledItems: isDisabled ? [...acc.disabledItems, state_code] : acc.disabledItems,
            nexusByStateCode: { ...acc.nexusByStateCode, [state_code]: curr },
            //get unique countries
            countries: countryExists
              ? acc.countries
              : [...acc.countries, { label: getCountryNameByCode(country_code), value: country_code }],
          };
        },
        { jurisdictions: [], disabledItems: [], nexusByStateCode: {}, countries: [] }
      );
    },
    enabled: !!orgId,
    refetchOnWindowFocus: false,
  });

  const countriesCollection = useMemo(() => {
    return createListCollection({
      items: countries,
    });
  }, [countries]);

  const mutation = useMutation({
    mutationFn: ({ id, payload }: { id: string; payload: Partial<RegistrationInstance> }) => {
      return registerNexus(id, orgId, payload);
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: [REGISTRATION_STATE_KEY] });
      queryClient.invalidateQueries({ queryKey: [NEXUS_STATE_KEY] });
      queryClient.removeQueries({ queryKey: [...ORGANIZATION_SERVICE_QUERY_KEYS.list(orgId)] });
      queryClient.invalidateQueries({ queryKey: [...ORGANIZATION_SERVICE_QUERY_KEYS.details(orgId)] });

      onClose();
      if (vdaRequested) {
        handleSuccessNotification(
          'VDA exploration request successfully submitted. We may contact you for more information.'
        );
        setVdaRequested(false);
      } else {
        handleSuccessNotification(
          nexusId ? 'Registration updated successfully.' : 'Registration request successfully submitted'
        );
      }
      formik.resetForm();
    },
    onError: error => {
      handleFailNotification(error);
    },
  });

  const formik = useFormik({
    initialValues: {
      country_code: data?.country_code || CountryCodeEnum.US,
      state_code: data?.state_code as string,
      registration_email: orgDetails?.email_group
        ? (orgDetails?.email_group as string)
        : (data?.registration_email as string),
      status: RegistrationStatus.REGISTERED,
      is_approaching: false,
      comment: '',
      vda: false,
      tax_id: orgDetails?.tax_ids?.find(tax => tax.country_code === CountryCodeEnum.CA)?.tax_id || '',
      auto_register: orgDetails?.auto_register,
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    validate: values => {
      if (!nexusId && !values.is_approaching) {
        return {
          is_approaching: 'Make sure to check this box if you are not sure if you have met nexus here.',
        };
      }
    },
    onSubmit: values => {
      if (!values.state_code) return;
      mutation.mutate({
        id: nexusId ? nexusId : nexusByStateCode[values.state_code].id!,
        payload: values,
      });
    },
  });

  const jurisdictionItems = useMemo(() => {
    const filteredJurisdictions = jurisdictions.filter(
      jurisdiction => nexusByStateCode[jurisdiction.value]?.country_code === formik.values.country_code
    );

    return createListCollection({
      items: filteredJurisdictions,
      isItemDisabled: ({ value }) => !!disabledItems.find(disabledValue => value === disabledValue),
    });
  }, [jurisdictions, formik.values.country_code, nexusByStateCode, disabledItems]);

  const isCountryCodeInvalid = !!(formik.touched.country_code && formik.errors.country_code);
  const isStateCodeInvalid = !!(formik.touched.state_code && formik.errors.state_code);
  const isRegistrationEmailInvalid = !!(formik.touched.registration_email && formik.errors.registration_email);
  const isApproachingInvalid = !!(formik.touched.is_approaching && formik.errors.is_approaching);

  const showRegistrationRegime =
    data?.country_code === CountryCodeEnum.CA && (data?.state_code === 'FD' || data?.state_code === 'QC');
  const isSimplifiedRegime = data?.registration_regime === RegistrationsRegimeEnum.SIMPLIFIED;

  useEffect(() => {
    if (isError) {
      onClose();
    }
  }, [isError]);

  useEffect(() => {
    if (!orgDetails?.email_group && !data?.registration_email) {
      setEdit(true);
    }
  }, [orgDetails, data]);

  useEffect(() => {
    if (requestRegistration && !isPaidUser && !isTest) {
      openPaymentCheckout({
        onClose: () => {
          setSearchParams({});
        },
      });
      return;
    }
  }, [requestRegistration]);

  const handleOpenRegistrationForm = () => {
    onClose();
    setSearchParams({ importRegistration: 'true' });
  };

  const handleExploreVdaClick = () => {
    formik.setValues({ ...formik.values, vda: true });
    formik.handleSubmit();
    setVdaRequested(true);
  };

  const handleVdaModalClose = () => {
    setSearchParams({});
    setVdaRequested(false);
  };

  const handleStandardRegistrationClick = () => {
    setSearchParams({ requestRegistration: 'true', nexusId: nexusId || '' });
  };

  if (vda) {
    return (
      <VdaSuggestionModal
        isOpen={vda === 'true'}
        onClose={handleVdaModalClose}
        onExploreVdaClick={handleExploreVdaClick}
        onStandardRegistrationClick={handleStandardRegistrationClick}
        isLoading={mutation.isPending}
      />
    );
  }

  if (!requestRegistration || (!isTest && !isPaidUser)) {
    return null;
  }

  return (
    <DialogRoot closeOnInteractOutside={false} size={'md'} open={requestRegistration}>
      <DialogContent colorPalette="blue" ref={contentRef}>
        <DialogHeader>
          <DialogTitle>Request Registration</DialogTitle>
        </DialogHeader>
        <DialogBody pt={0}>
          {isLoading || isOrgDetailsLoading ? (
            <VStack gap={4}>
              {Array.from({ length: 3 }, (_, index) => (
                <Skeleton key={index} height="30px" width="full" />
              ))}
            </VStack>
          ) : (
            <>
              {requestRegSource === 'not-exposed' && (
                <Text pb={4} color={'gray.900'}>
                  Please provide the required details so that we can proceed with your request.
                </Text>
              )}
              <SimpleGrid rowGap={4}>
                <SimpleGrid columns={2} gap={4}>
                  <Field
                    label="Country"
                    required
                    invalid={isCountryCodeInvalid}
                    errorText={formik.errors.country_code?.toString()}
                  >
                    {nexusId ? (
                      <Input disabled value={getCountryNameByCode(data?.country_code)} />
                    ) : (
                      <SelectRoot
                        name="country_code"
                        value={formik.values.country_code ? [formik.values.country_code] : undefined}
                        onValueChange={({ value }) => {
                          formik.setFieldValue('country_code', value[0]);
                          formik.setFieldValue('state_code', '');
                        }}
                        collection={countriesCollection}
                      >
                        <SelectTrigger>
                          <SelectValueText placeContent={'Select'} />
                        </SelectTrigger>
                        <SelectContent portalRef={contentRef}>
                          {countriesCollection.items.map(item => {
                            return (
                              <SelectItem item={item} key={item.value}>
                                <Text>{item.label}</Text>
                              </SelectItem>
                            );
                          })}
                        </SelectContent>
                      </SelectRoot>
                    )}
                  </Field>
                  <Field
                    label="Jurisdiction"
                    invalid={isStateCodeInvalid}
                    required
                    errorText={formik.errors.state_code?.toString()}
                  >
                    {nexusId ? (
                      <Input
                        disabled
                        value={getHumanReadableString(nexusByStateCode[formik.values.state_code]?.state_name)}
                      />
                    ) : (
                      <SelectRoot
                        name="state_code"
                        value={formik.values.state_code ? [formik.values.state_code] : undefined}
                        onValueChange={({ value }) => {
                          formik.setFieldValue('state_code', value[0]);
                        }}
                        collection={jurisdictionItems}
                        disabled={jurisdictionsLoading}
                      >
                        <SelectTrigger>
                          <SelectValueText placeholder={'Select'} />
                        </SelectTrigger>
                        <SelectContent portalRef={contentRef}>
                          {jurisdictionItems.items.map(item => {
                            return (
                              <Tooltip
                                key={item.value}
                                content="You have either met nexus here or are already registered."
                                openDelay={100}
                                disabled={!disabledItems.includes(item.value)}
                                positioning={{ placement: 'right-start', offset: { mainAxis: -36, crossAxis: 18 } }}
                              >
                                <Box cursor="not-allowed">
                                  <SelectItem item={item} key={item.value}>
                                    <Text>{item.label}</Text>
                                  </SelectItem>
                                </Box>
                              </Tooltip>
                            );
                          })}
                        </SelectContent>
                      </SelectRoot>
                    )}
                  </Field>
                </SimpleGrid>
                {showRegistrationRegime && (
                  <Field
                    label="Registration Regime"
                    tooltip={
                      isSimplifiedRegime
                        ? "The 'Simplified' regime can be used only when you satisfy certain conditions, such as not having a physical presence in Canada and selling certain types of products. Under this regime, you don't need to collect sales tax when your customer provides GST/HST registration number."
                        : undefined
                    }
                  >
                    <Input disabled value={getHumanReadableString(data?.registration_regime)} />
                  </Field>
                )}
                {formik.values.country_code === CountryCodeEnum.CA && (
                  <Field
                    label="Canada Business Number"
                    invalid={!!(formik.touched.tax_id && formik.errors.tax_id)}
                    errorText={formik.errors.tax_id?.toString()}
                  >
                    <Input id="tax_id" name="tax_id" value={formik.values.tax_id} onChange={formik.handleChange} />
                  </Field>
                )}
                <Field
                  label="Registration Email"
                  invalid={isRegistrationEmailInvalid}
                  required
                  errorText={formik.errors.registration_email}
                  tooltip={
                    'Your virtual mail group is used by default to keep stakeholders in the loop. You can edit this and use a different email instead.'
                  }
                >
                  {edit ? (
                    <Input
                      id="registration_email"
                      type="email"
                      name="registration_email"
                      value={formik.values.registration_email}
                      onChange={formik.handleChange}
                    />
                  ) : (
                    <HStack justifyContent={'space-between'} w="full">
                      <Text>{formik.values.registration_email}</Text>
                      <MenuRoot>
                        <MenuTrigger asChild>
                          <IconButton variant="transparent-with-icon">
                            <MdMoreVert size={'20px'} />
                          </IconButton>
                        </MenuTrigger>
                        <MenuContent portalRef={contentRef}>
                          <MenuItem value="edit" onClick={() => setEdit(true)}>
                            Edit
                          </MenuItem>
                        </MenuContent>
                      </MenuRoot>
                    </HStack>
                  )}
                </Field>
                <Field label="Comment">
                  <Textarea
                    id="comment"
                    name="comment"
                    value={formik.values.comment}
                    border={'1px solid #CFD0D899'}
                    borderRadius={'2px'}
                    onChange={formik.handleChange}
                    resize={'none'}
                    _focus={{
                      borderColor: 'secondary.500',
                    }}
                  />
                </Field>

                {isPaidUser && !orgDetails?.auto_register && (
                  <AutoFileRegisterToggle
                    label="Auto Register"
                    fieldName="auto_register"
                    formik={formik}
                    p={2}
                    my={2}
                    fontSize={'sm'}
                    tooltip={
                      <>
                        <Text color={'white'} mb={2}>
                          Automatically approve registrations as soon as the nexus is met. You can toggle this OFF
                          anytime from Settings.
                        </Text>
                        <Text color={'white'}>
                          Please ensure you complete your tasks, such as validating addresses and approving products so
                          that registrations can be auto-approved.
                        </Text>
                      </>
                    }
                  />
                )}
                {!nexusId && (
                  <Field invalid={isApproachingInvalid} errorText={formik.errors.is_approaching}>
                    <Checkbox
                      disabled={!isPaidUser}
                      name="is_approaching"
                      checked={formik.values.is_approaching}
                      onCheckedChange={({ checked }) => formik.setFieldValue('is_approaching', checked)}
                    >
                      <Text fontWeight={'normal'}>
                        I understand that registration in this jurisdiction may not be necessary since I haven&apos;t
                        met nexus here.
                      </Text>
                    </Checkbox>
                  </Field>
                )}
              </SimpleGrid>
            </>
          )}
        </DialogBody>
        <DialogFooter>
          <Flex justifyContent={'space-between'} width={'full'} align={'center'}>
            <Text color={'secondary.500'} onClick={handleOpenRegistrationForm} cursor={'pointer'}>
              Already Registered?
            </Text>

            <Flex gap={2}>
              <Button variant={'outline'} onClick={onClose}>
                Cancel
              </Button>
              <Button
                loading={mutation.isPending}
                disabled={formik.isSubmitting || !formik.isValid}
                variant={'solid'}
                colorPalette={'blue'}
                width={'90px'}
                onClick={() => {
                  if (data?.imported) {
                    formik.setValues({ ...formik.values, status: RegistrationStatus.REGISTERED });
                  } else {
                    formik.setValues({ ...formik.values, status: RegistrationStatus.PROCESSING });
                  }
                  if (formik.isValid) {
                    formik.handleSubmit();
                  } else {
                    formik.setTouched({
                      registration_email: true,
                    });
                  }
                }}
              >
                {requestRegSource === 'not-exposed' ? 'Confirm' : 'Save'}
              </Button>
            </Flex>
          </Flex>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};
