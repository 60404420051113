import { Box, Flex, HStack, Table, Text, useDisclosure } from '@chakra-ui/react';
import { Tooltip } from 'components/ui/tooltip';
import { TaxLiabilitySourceEnum } from 'types/shared-types';
import { NumberStyleEnum } from 'types/utils';
import { formatCurrency, formatNumber } from 'utils/utils';

import { ProductModal } from './product-modal';

type TransactionItemsTableProps = {
  captions: string[];
  data: any;
  taxLiabilitySource: TaxLiabilitySourceEnum;
  subtotal: {
    totalAmount: number;
    totalTaxAmount: number;
  };
};

const NUMERIC_COLUMNS = ['COLLECTED TAX', 'TAX LIABILITY', 'SALES'];

export const TransactionItemsTable = ({ captions, data, subtotal, taxLiabilitySource }: TransactionItemsTableProps) => {
  return (
    <>
      <Table.Root>
        <Table.Header>
          <Table.Row>
            {captions.map((caption: any, idx: number) => {
              return (
                <Table.Cell key={idx} textAlign={NUMERIC_COLUMNS.includes(caption) ? 'end' : 'start'}>
                  {caption}
                </Table.Cell>
              );
            })}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data?.map((row: any) => (
            <TransactionItemsTableRow
              key={row.id}
              id={row.id}
              product={row.product}
              product_id={row.product_id}
              description={row.description}
              tax_liability_source={taxLiabilitySource}
              quantity={row.quantity}
              tax_amount_calculated={row.tax_amount_calculated}
              tax_amount_imported={row.tax_amount_imported}
              amount={row.amount}
              tax_items={row.tax_items}
            />
          ))}
        </Table.Body>
      </Table.Root>
      <Box w={'262px'} ml="auto">
        <HStack p={'8px 20px 8px 31px'} justifyContent={'space-between'}>
          <Text>Subtotal</Text>
          <Text>{formatCurrency(subtotal.totalAmount)}</Text>
        </HStack>
        <HStack p={'8px 20px 8px 31px'} justifyContent={'space-between'}>
          <Text>Total Tax</Text>
          <Text>{formatCurrency(subtotal.totalTaxAmount)}</Text>
        </HStack>
        <HStack p={'8px 20px 8px 31px'} justifyContent={'space-between'} bgColor={'#ECF3FE'}>
          <Text fontWeight={500}>Total</Text>
          <Text fontWeight={500}>{formatCurrency(subtotal.totalAmount + subtotal.totalTaxAmount)}</Text>
        </HStack>
      </Box>
    </>
  );
};

const TransactionItemsTableRow = ({
  product,
  product_id,
  description,
  quantity,
  tax_liability_source,
  tax_items,
  amount,
  tax_amount_imported,
  tax_amount_calculated,
}: any) => {
  const { open, onOpen, onClose } = useDisclosure();
  const tax_amount =
    tax_liability_source === TaxLiabilitySourceEnum.CALCULATED ? tax_amount_calculated : tax_amount_imported;
  return (
    <>
      <Table.Row onClick={onOpen} _hover={{ cursor: 'pointer' }}>
        <Table.Cell width={{ sm: '250px' }}>
          <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
            <Text>{product}</Text>
          </Flex>
        </Table.Cell>
        <Table.Cell width={{ sm: '380px' }}>
          <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
            <Text>{description}</Text>
          </Flex>
        </Table.Cell>
        <Table.Cell width={{ sm: '200px' }}>
          <Text>{quantity}</Text>
        </Table.Cell>
        <Table.Cell width={{ sm: '170px' }} textAlign="end">
          <Text>{formatCurrency(tax_amount_imported)}</Text>
        </Table.Cell>
        <Table.Cell width={{ sm: '170px' }} textAlign="end">
          {tax_amount > 0 && tax_items?.length > 0 ? (
            <Tooltip
              content={tax_items.map((item: any, index: number) => (
                <>
                  <HStack p={'8px'}>
                    <Box w={'105px'}>
                      {' '}
                      <Text color={'white'} fontWeight="medium">
                        {item.name}
                      </Text>
                    </Box>
                    <Box minW={'60px'} mr={'30px'}>
                      {' '}
                      <Text color={'white'}> {formatNumber(item.rate, { style: NumberStyleEnum.PERCENT })}</Text>
                    </Box>
                    <Box minW={'50px'}>
                      {' '}
                      <Text color={'white'}> {formatCurrency(item.amount)}</Text>
                    </Box>
                  </HStack>
                  {index < tax_items.length - 1 && <hr style={{ borderTop: '1px solid #383D58' }} />}
                </>
              ))}
            >
              <Text>{formatCurrency(tax_amount)}</Text>
            </Tooltip>
          ) : (
            <Text>{formatCurrency(tax_amount)}</Text>
          )}
        </Table.Cell>
        <Table.Cell width={{ sm: '220px' }} textAlign="end">
          <Text>{formatCurrency(amount)}</Text>
        </Table.Cell>
      </Table.Row>
      {product_id && open && <ProductModal isOpen={open} onClose={onClose} product_id={product_id} />}
    </>
  );
};
