import * as Yup from 'yup';

export enum CreditsType {
  REFUND = 'REFUND',
  OVERPAYMENT = 'OVERPAYMENT',
  ITC = 'ITC',
}

export type CreditsListType = {
  id: string;
  type: CreditsType;
  start_date: string;
  end_date: string;
  amount: string;
  amount_consumed: number;
  amount_remaining: number;
  organization_id: string;
  registration_id: string;
};

export const CreditsValidationSchema = Yup.object().shape({
  registration_id: Yup.string().required('Registration ID is required'),
  transaction_id: Yup.string().optional(),
  country_code: Yup.string().optional(),
  state_code: Yup.string().optional(),
  type: Yup.string().required('Type is required'),
  amount: Yup.string().required('Credits amount is required'),
  start_date: Yup.string().required('Start date is required'),
  end_date: Yup.string().optional().nullable(),
  comment: Yup.string().optional(),
});

export type CreditsValidationSchemaType = Yup.InferType<typeof CreditsValidationSchema>;
export type CreditsPayload = Omit<Yup.InferType<typeof CreditsValidationSchema>, 'country_code' | 'state' | 'comment'>;
