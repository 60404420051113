import { Badge, Flex, HStack, Skeleton, Stack, Table, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { getCollectedTransactionsByNexusId } from 'apis/nexus';
import { AppHeader } from 'app/app-header';
import { KDataTable } from 'components/table/data-table';
import { TableEmptyState } from 'components/table-empty-state/table-empty-state';
import { Tooltip } from 'components/ui/tooltip';
import { transactionStatusVariantMap } from 'constants/transactions';
import { useOrg } from 'hooks/useOrg';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Source, TransactionInstance } from 'types/shared-types';
import { TransactionStatus } from 'types/transactions';
import { getHumanReadableString } from 'utils/enum-helpers';
import { formatNumber } from 'utils/utils';

export const CollectedTransactions = () => {
  const { nexusId } = useParams<{ nexusId: string }>();
  const { orgId } = useOrg();
  const id = nexusId ?? '';

  const { data, isLoading, isError } = useQuery({
    queryKey: ['collected-transactions', id],
    queryFn: async () => {
      const response = await getCollectedTransactionsByNexusId(orgId, id);
      return response.data;
    },
  });

  const TABLE_HEAD = [
    'ID',
    'CUSTOMER ID',
    'DESCRIPTION',
    'DATE',
    'JURISDICTION',
    'AMOUNT',
    'TAX LIABILITY',
    'TAX LIABILITY SOURCE',
    'CURRENCY',
    'CURRENCY CONVERSION RATE',
    'CONVERTED CURRENCY',
    'TRANSACTION SOURCE',
    'EXEMPT',
    'MARKETPLACE',
    'STATUS',
  ];

  const COLUMN_WIDTHS = {
    ID: '100px',
    'CUSTOMER ID': '163px',
    DESCRIPTION: '253px',
    DATE: '120px',
    JURISDICTION: '110px',
    AMOUNT: '133px',
    'TAX LIABILITY': '157px',
    'TRANSACTION SOURCE': '166px',
    EXEMPT: '60px',
    MARKETPLACE: '60px',
    STATUS: '114px',
  };

  const NUMERIC_COLUMNS = ['AMOUNT', 'TAX LIABILITY', 'CURRENCY CONVERSION RATE'];

  const HIDE_COLUMNS = [
    'TRANSACTION SOURCE',
    'EXEMPT',
    'MARKETPLACE',
    'CURRENCY',
    'CURRENCY CONVERSION RATE',
    'CONVERTED CURRENCY',
    'TYPE',
    'TAX LIABILITY SOURCE',
    'TAX LIABILITY',
  ];

  const DEFAULT_VISIBLE_COLUMNS = TABLE_HEAD.filter(header => !HIDE_COLUMNS.includes(header));
  const [visibleColumns, setVisibleColumns] = useState<string[]>(DEFAULT_VISIBLE_COLUMNS);

  const handleToggleColumn = (columns: string[]) => {
    setVisibleColumns(columns);
  };

  if (isLoading) return <Skeleton />;
  if (data?.length === 0 || isError) return <TableEmptyState tableName="Collected Transactions" />;
  return (
    <Stack>
      <HStack gap={1} justifyContent={'space-between'} w={'full'}>
        <AppHeader />
      </HStack>
      <KDataTable
        headers={TABLE_HEAD}
        numericCols={NUMERIC_COLUMNS}
        defaultVisibleColumns={visibleColumns}
        onVisibleColumnsChange={handleToggleColumn}
        showColumnFilter
        columnWidths={COLUMN_WIDTHS}
        isPending={isLoading}
      >
        {data?.map(({ id, addresses, ...rest }: TransactionInstance) => (
          <CollectedTransactionTableRow
            key={id}
            id={id}
            addresses={addresses}
            {...rest}
            visibleColumns={visibleColumns}
          />
        ))}
      </KDataTable>
    </Stack>
  );
};

const CollectedTransactionTableRow = ({
  id,
  customer_id,
  description,
  date,
  state,
  total_amount,
  total_tax_liability_amount,
  currency,
  conversion_rate,
  destination_currency,
  source,
  exempt,
  marketplace,
  external_friendly_id,
  external_id,
  status,
  type,
  visibleColumns,
  tax_liability_source,
}: TransactionInstance & { visibleColumns: string[] }) => {
  const sourceWithFriendlyId = [Source.QUICKBOOKS, Source.SHOPIFY].includes(source);
  const displayId = sourceWithFriendlyId && external_friendly_id ? external_friendly_id : external_id;
  const statusVariant = transactionStatusVariantMap[status as TransactionStatus];
  const amountTextColor = type === 'FULL_CREDIT_NOTE' || type === 'PARTIAL_CREDIT_NOTE' ? '#DD6B20' : '#262B47';
  const dateObject = new Date(date);
  const formattedDate = `${dateObject.toLocaleString('default', {
    month: 'short',
  })} ${dateObject.getDate()}, ${dateObject.getFullYear()}`;
  const navigate = useNavigate();
  const handleRowClick = () => {
    navigate(`/transactions/${id}`);
  };
  return (
    <Table.Row
      onClick={handleRowClick}
      _hover={{
        bg: '#EFEFF3',
        cursor: 'pointer',
      }}
      width={'100%'}
    >
      {visibleColumns.includes('ID') && (
        <Table.Cell maxW="6.25rem">
          <Tooltip content={displayId}>
            <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
              <Text lineClamp={1}>{displayId}</Text>
            </Flex>
          </Tooltip>
        </Table.Cell>
      )}
      {visibleColumns.includes('CUSTOMER ID') && (
        <Table.Cell>
          <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
            <Text>{customer_id}</Text>
          </Flex>
        </Table.Cell>
      )}
      {visibleColumns.includes('DESCRIPTION') && (
        <Table.Cell maxW="17.5rem">
          <Tooltip content={description}>
            <Flex alignItems="center" flexWrap="nowrap">
              <Text lineClamp={1} maxWidth={'100%'}>
                {description}
              </Text>
            </Flex>
          </Tooltip>
        </Table.Cell>
      )}
      {visibleColumns.includes('DATE') && (
        <Table.Cell>
          <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
            <Text>{formattedDate}</Text>
          </Flex>
        </Table.Cell>
      )}
      {visibleColumns.includes('JURISDICTION') && (
        <Table.Cell>
          <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
            <Text>{state}</Text>
          </Flex>
        </Table.Cell>
      )}
      {visibleColumns.includes('AMOUNT') && (
        <Table.Cell textAlign="end">
          <Text color={amountTextColor}>{formatNumber(total_amount)}</Text>
        </Table.Cell>
      )}
      {visibleColumns.includes('TAX LIABILITY') && (
        <Table.Cell textAlign="end">
          <Text color={amountTextColor}>{formatNumber(total_tax_liability_amount)}</Text>
        </Table.Cell>
      )}
      {visibleColumns.includes('TAX LIABILITY SOURCE') && (
        <Table.Cell>
          <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
            <Text>{tax_liability_source}</Text>
          </Flex>
        </Table.Cell>
      )}
      {visibleColumns.includes('CURRENCY') && (
        <Table.Cell>
          <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
            <Text>{currency}</Text>
          </Flex>
        </Table.Cell>
      )}
      {visibleColumns.includes('CURRENCY CONVERSION RATE') && (
        <Table.Cell>
          <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
            <Text>{conversion_rate}</Text>
          </Flex>
        </Table.Cell>
      )}
      {visibleColumns.includes('CONVERTED CURRENCY') && (
        <Table.Cell>
          <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
            <Text>{destination_currency}</Text>
          </Flex>
        </Table.Cell>
      )}
      {visibleColumns.includes('TRANSACTION SOURCE') && (
        <Table.Cell>
          <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
            <Text>{getHumanReadableString(source)}</Text>
          </Flex>
        </Table.Cell>
      )}
      {visibleColumns.includes('EXEMPT') && (
        <Table.Cell>
          <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
            <Text>{exempt === 'NOT_EXEMPT' ? 'False' : 'True'}</Text>
          </Flex>
        </Table.Cell>
      )}
      {visibleColumns.includes('MARKETPLACE') && (
        <Table.Cell>
          <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
            <Text>{marketplace ? 'True' : 'False'}</Text>
          </Flex>
        </Table.Cell>
      )}
      {visibleColumns.includes('STATUS') && (
        <Table.Cell>
          <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
            {statusVariant && <Badge colorPalette={statusVariant.variant}>{statusVariant.title}</Badge>}
          </Flex>
        </Table.Cell>
      )}
    </Table.Row>
  );
};

export default CollectedTransactions;
