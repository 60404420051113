import { Box, Flex, Link, Text, useDisclosure } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { BILLING_STATE_KEY } from 'apis/billing-apis';
import {
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from 'components/ui/dialog';
import { BILLING_PLANS } from 'constants/billing-plans';
import { useOrg } from 'hooks/useOrg';
import useQueryParam from 'hooks/useQueryParam';
import { useEffect } from 'react';

import { PricingCard } from './pricing-card';

export const UpgradeBillingPlanFromFreeModal = () => {
  const { orgId } = useOrg();
  const queryClient = useQueryClient();
  const { open, onClose, onOpen } = useDisclosure();
  const [openPricingModal, setSearchParams] = useQueryParam('openPricingModal', '');
  const handleUpgradeSuccess = () => {
    queryClient.invalidateQueries({
      queryKey: [BILLING_STATE_KEY, 'details', orgId],
    });
    queryClient.invalidateQueries({
      queryKey: [BILLING_STATE_KEY, 'invoices', orgId],
    });
  };

  useEffect(() => {
    if (openPricingModal) {
      onOpen();
    }
  }, [openPricingModal]);

  return (
    <DialogRoot
      open={open}
      placement="center"
      onOpenChange={details => {
        if (!details.open) {
          setSearchParams({});
        }
      }}
      onInteractOutside={onClose}
      size={'xl'}
    >
      <DialogContent width="47.25rem">
        <DialogHeader>
          <DialogTitle>Upgrade Plan</DialogTitle>
        </DialogHeader>
        <DialogCloseTrigger onClick={onClose} />
        <DialogBody pt={0}>
          <Flex justify={'space-between'}>
            {BILLING_PLANS.map(plan => (
              <PricingCard key={plan.title} {...plan} onClose={onClose} onUpgradeSuccess={handleUpgradeSuccess} />
            ))}
          </Flex>
        </DialogBody>
        <Box py={2} ml={6}>
          <Text fontSize={'xs'}>The Growth plan is billed in arrears on the first day of the next month</Text>
        </Box>
        <DialogFooter justifyContent={'start'}>
          <Text>
            Questions?
            <Link ml={1} color="#4285F4" href="mailto:growth@trykintsugi.com">
              Contact Sales
            </Link>
          </Text>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};
