import { Box, createListCollection, Flex, HStack, Input, Spinner } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { TRANSACTION_STATE_KEY, updateAddress } from 'apis/transaction-api';
import { AddressSuggestionPopover } from 'components/Address/AddressSuggestionPopover';
import { Button } from 'components/ui/button';
import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from 'components/ui/dialog';
import { Field } from 'components/ui/field';
import { SelectContent, SelectItem, SelectRoot, SelectTrigger, SelectValueText } from 'components/ui/select';
import { useFormik } from 'formik';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useLocationData } from 'hooks/useLocationData';
import { useOrg } from 'hooks/useOrg';
import { useEffect, useMemo, useRef, useState } from 'react';
import { SuggestedAddress } from 'types/address';
import { LocationOptionType } from 'types/location';
import { AddressInstance, AddressStatus } from 'types/shared-types';
import { toNormalCase } from 'utils';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  city: Yup.string().required('City is required'),
  state: Yup.string().test('state-required', 'State is required', function (value) {
    const { country } = this.parent;
    if (country === 'US') {
      return !!value;
    }
    return true;
  }),
  postal_code: Yup.string().required('Postal Code is required'),
  country: Yup.string().required('Country is required'),
  full_address: Yup.string().optional().nullable(),
});

interface EditAddressModalType {
  isOpen: boolean;
  onClose: () => void;
  payload: Partial<AddressInstance>;
}

export const EditTransactionAddressForm = ({ isOpen, onClose, payload }: EditAddressModalType) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const { orgId } = useOrg();
  const queryClient = useQueryClient();
  const { handleSuccessNotification } = useHandleNotification();

  const { countries, getStates } = useLocationData();
  const [stateOptions, setStateOptions] = useState<LocationOptionType[]>([]);

  const mutation = useMutation({
    mutationFn: (payload: Partial<AddressInstance>[]) => {
      return updateAddress(orgId, payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [TRANSACTION_STATE_KEY] });
      onClose();
      handleSuccessNotification('Transaction address edited successfully.');
    },
  });

  const formik = useFormik({
    initialValues: {
      phone: payload?.phone,
      street_1: payload?.street_1,
      street_2: payload?.street_2,
      city: payload?.city,
      county: payload?.county,
      state: payload?.state,
      postal_code: payload?.postal_code,
      country: payload?.country,
      type: payload?.type,
      status: AddressStatus.UNVERIFIED,
      id: payload?.id,
      transaction_id: payload?.transaction_id,
      full_address: payload?.full_address,
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: values => {
      values.status = AddressStatus.UNVERIFIED;
      mutation.mutate([values]);
    },
  });

  useEffect(() => {
    const fetchStates = async () => {
      const states = await getStates(formik.values.country ?? '');
      setStateOptions(states);
    };
    fetchStates();
  }, [getStates, formik.values.country]);

  const handleStateChange = (value: string) => {
    formik.setFieldValue('state', value ?? '');
  };

  const handleCountryChange = (value: string) => {
    if (value !== 'US') {
      formik.setFieldValue('state', '');
      formik.setFieldValue('county', '');
      formik.setFieldValue('country', value);
    } else {
      formik.setFieldValue('country', value);
    }
  };

  const applySelectedAddress = (address: SuggestedAddress) => {
    formik.setValues({
      phone: payload?.phone,
      street_1: formik.values.street_1 ?? '',
      street_2: formik.values.street_2 ?? '',
      country: address.country,
      type: payload?.type,
      status: AddressStatus.UNVERIFIED,
      id: payload?.id,
      transaction_id: payload?.transaction_id,
      city: address.city,
      county: address.county,
      state: address.state,
      postal_code: address.postal_code,
      full_address: address.full_address,
    });
  };

  const countriesCollection = useMemo(() => {
    return createListCollection({
      items: countries,
    });
  }, [countries]);

  const statesCollection = useMemo(() => {
    return createListCollection({
      items: stateOptions,
    });
  }, [stateOptions]);

  const onCloseModal = () => {
    formik.resetForm();
    onClose();
  };

  return (
    <DialogRoot
      size={'lg'}
      open={isOpen}
      onOpenChange={({ open }) => {
        if (!open) {
          formik.resetForm();
        }
      }}
    >
      <DialogBackdrop />
      <DialogContent>
        <DialogCloseTrigger onClick={onCloseModal} />
        <DialogHeader>
          <DialogTitle>{payload?.type === 'BILL_TO' ? 'Bill To' : 'Ship To'}</DialogTitle>
        </DialogHeader>
        <DialogBody ref={contentRef}>
          <form onSubmit={formik.handleSubmit} noValidate>
            <HStack align={'baseline'}>
              <Field
                label="Country"
                invalid={!!(formik.errors.country && formik.touched.country)}
                errorText={formik.errors.country}
                required
              >
                <SelectRoot
                  collection={countriesCollection}
                  value={formik.values.country ? [formik.values.country] : undefined}
                  onValueChange={({ value }) => handleCountryChange(value[0])}
                >
                  <SelectTrigger>
                    <SelectValueText placeholder="Select" />
                  </SelectTrigger>
                  <SelectContent portalRef={contentRef}>
                    {countriesCollection.items.map(item => (
                      <SelectItem key={item.value} item={item}>
                        {item.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </SelectRoot>
              </Field>

              <Field
                label="State"
                invalid={!!(formik.errors.state && formik.touched.state)}
                errorText={formik.errors.state}
                required
              >
                <SelectRoot
                  collection={statesCollection}
                  value={formik.values.state ? [formik.values.state] : undefined}
                  onValueChange={({ value }) => handleStateChange(value[0])}
                >
                  <SelectTrigger>
                    <SelectValueText placeholder="Select" />
                  </SelectTrigger>
                  <SelectContent portalRef={contentRef}>
                    {statesCollection.items.map(item => (
                      <SelectItem key={item.value} item={item}>
                        {item.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </SelectRoot>
              </Field>
            </HStack>
            <Box height={'16px'} />
            <HStack align={'baseline'}>
              <Field
                label="City"
                invalid={!!(formik.errors.city && formik.touched.city)}
                errorText={formik.errors.city}
                required
              >
                <Input
                  id="city"
                  type="text"
                  name="city"
                  value={toNormalCase(formik.values.city ?? '')}
                  onChange={formik.handleChange}
                  placeholder={payload?.city}
                />
              </Field>

              <Field
                label="Postal Code"
                invalid={!!(formik.errors.postal_code && formik.touched.postal_code)}
                errorText={formik.errors.postal_code}
                required
              >
                <Input
                  id="postal_code"
                  name="postal_code"
                  value={formik.values.postal_code ?? ''}
                  onChange={formik.handleChange}
                  placeholder={payload?.postal_code}
                />
              </Field>
            </HStack>
            <Box height={'16px'} />
            <HStack align={'baseline'}>
              <Field label="Address Line 1">
                <Input
                  id="street_1"
                  type="text"
                  name="street_1"
                  value={formik.values.street_1 ?? ''}
                  onChange={formik.handleChange}
                  placeholder={payload?.street_1}
                />
              </Field>
              <Field label="Address Line 2">
                <Input
                  id="street_2"
                  type="text"
                  name="street_2"
                  value={formik.values.street_2 ?? ''}
                  onChange={formik.handleChange}
                  placeholder={payload?.street_2}
                />
              </Field>
            </HStack>
            <Box height={'16px'} />
            <HStack align={'baseline'}>
              <Field label="Full Address">
                <Input
                  id="full_address"
                  type="text"
                  name="full_address"
                  value={formik.values.full_address ?? ''}
                  onChange={formik.handleChange}
                  placeholder={payload?.full_address}
                />
              </Field>
            </HStack>
            <Box height={'16px'} />
            {isOpen && formik.values && (
              <AddressSuggestionPopover
                address={formik.values}
                onUpdateAddress={applySelectedAddress}
                isParentOpen={isOpen}
              />
            )}
          </form>
        </DialogBody>
        <DialogFooter>
          <Flex gap={2}>
            <Button variant={'outline'} color={'secondary'} onClick={onCloseModal}>
              Cancel
            </Button>
            <Button variant={'solid'} colorPalette="blue" width={'90px'} onClick={() => formik.handleSubmit()}>
              {mutation.isPending && <Spinner />}
              Save
            </Button>
          </Flex>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};
