import { useOrgDetailsQuery } from 'apis/organizations-apis';
import { useOrg } from 'hooks/useOrg';
import { useState } from 'react';

import { BusinessAddressPrompt } from '../../update-address-prompt';
import { ItemCard } from '../item-card';
import { BigcommerceIcon } from './icons/bigcommerce';

export default function BigCommerceTab({ isConnected = false }: { isConnected?: boolean }) {
  const { orgId } = useOrg();

  const [isBusinessAddressPromptOpen, setIsBusinessAddressPromptOpen] = useState(false);
  const { data } = useOrgDetailsQuery(orgId);
  const isAddressMissing = data ? !data.company_country_code || !data.company_postal_code : true;

  const openInNewTab = () => {
    window.open('https://www.bigcommerce.com/apps/kintsugi-tax/', '_blank', 'noopener,noreferrer');
  };

  return (
    <>
      <ItemCard
        isConnected={isConnected}
        title="BigCommerce"
        description=" Enterprise ecommerce, simplified"
        icon={<BigcommerceIcon />}
        btnProps={{ colorPalette: 'lightGray' }}
        onConnect={() => (isAddressMissing ? setIsBusinessAddressPromptOpen(true) : openInNewTab())}
        installationGuide="https://intercom.help/kintsugi/en/articles/10167255-bigcommerce-installation-guide#h_d18e02fa61"
      />
      <BusinessAddressPrompt
        isOpen={isBusinessAddressPromptOpen}
        onClose={() => setIsBusinessAddressPromptOpen(false)}
        onSuccess={openInNewTab}
      />
    </>
  );
}
