import { Grid, HStack, Skeleton, Stack, Tabs } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { CONNECTIONS_STATE_KEY, getConnections } from 'apis/connections';
import AppHeader from 'app/app-header';
import { useOrg } from 'hooks/useOrg';
import { cloneElement, Fragment } from 'react';
import { ConnectionStatusEnum, SourceEnum } from 'schema/types-schema.d';
import { ApiResponse, ConnectionInstance } from 'types/shared-types';

import { integrationTabs } from './tabs';

const Integrations = () => {
  const { orgId } = useOrg();
  const { data, isPending } = useQuery<
    ApiResponse<ConnectionInstance>,
    unknown,
    Record<SourceEnum, ConnectionStatusEnum>
  >({
    queryKey: [CONNECTIONS_STATE_KEY, orgId],
    queryFn: async () => {
      try {
        const { data } = await getConnections(orgId);
        return data;
      } catch (error) {
        console.error('Failed to fetch connections:', error);
        Sentry.captureException(error, {
          tags: {
            component: 'Integrations',
            orgId,
          },
        });
        throw error;
      }
    },
    select: data => {
      const connectionMap = (data?.items || []).reduce(
        (map, { source, status }) => {
          map[source] = status;
          return map;
        },
        {} as Record<SourceEnum, ConnectionStatusEnum>
      );

      return Object.values(SourceEnum).reduce(
        (acc, curr) => {
          acc[curr] = connectionMap[curr] || ConnectionStatusEnum.INACTIVE;
          return acc;
        },
        {} as Record<SourceEnum, ConnectionStatusEnum>
      );
    },
    retry: 3,
    retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 30000),
  });

  const tabHeaders = integrationTabs.map(tab => tab.title);

  if (isPending) {
    return <Skeleton height="full" width="full" />;
  }

  return (
    <Stack>
      <HStack justifyContent="space-between">
        <AppHeader />
      </HStack>
      <Tabs.Root unmountOnExit colorPalette="blue" defaultValue={integrationTabs[0].title}>
        <Tabs.List>
          {tabHeaders.map((header, i) => (
            <Tabs.Trigger value={header} key={i} fontSize="sm">
              {header}
            </Tabs.Trigger>
          ))}
        </Tabs.List>
        {integrationTabs.map(({ cards, title }) => (
          <Tabs.Content value={title} maxWidth="1244px" px={0} key={title}>
            <Grid templateColumns={{ sm: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }} gap={6} alignItems="stretch">
              {cards.map(({ source, component }, i) => {
                const isConnected = data?.[source] === ConnectionStatusEnum.ACTIVE || false;
                return (
                  <Fragment key={`${title}-${i}`}>
                    {cloneElement(component, {
                      ...component.props,
                      isConnected,
                      onError: (error: Error) => {
                        console.error(`Integration error for ${source}:`, error);
                        Sentry.captureException(error, {
                          tags: {
                            component: 'Integration',
                            source,
                            orgId,
                          },
                        });
                      },
                    })}
                  </Fragment>
                );
              })}
            </Grid>
          </Tabs.Content>
        ))}
      </Tabs.Root>
    </Stack>
  );
};

export default Integrations;
