import { NexusPrediction, RegistrationInstance } from 'types/shared-types';

import api from './api';

const getNexus = (orgId: string) => {
  return api.get('/v1/nexus', {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const getNexusById = (nexusId: string, orgId: string) => {
  return api.get(`/v1/nexus/${nexusId}`, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const getNexusPrediction = (orgId: string, state_code: string) => {
  return api.get<NexusPrediction>(`/v1/nexus/predictions`, {
    params: {
      state_code,
    },
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const registerNexus = (nexusId: string, orgId: string, payload: Partial<RegistrationInstance>) => {
  return api.put(`v1/nexus/${nexusId}/register`, payload, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const getCollectedTransactionsByNexusId = (orgId: string, nexusId: string) => {
  return api.get(`/v1/nexus/${nexusId}/collected_transactions`, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

export { getCollectedTransactionsByNexusId, getNexus, getNexusById, getNexusPrediction, registerNexus };
